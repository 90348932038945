import React from 'react';
import "./Modal.css"

const ScrollableModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-scrollable" onClick={onClose}>
      <div className="modal-content-scrollable" onClick={(e) => {
          // do not close modal if anything inside modal content is clicked
          e?.stopPropagation();
        }}> 
        {/* <button className="modal-close" onClick={onClose}>
          Close
        </button> */}
        <div className="modal-body-scrollable">{children}</div>
      </div>
    </div>
  );
};

export default ScrollableModal;
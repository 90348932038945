import { useEffect, useState } from "react";
import googleTranslation from "../../assets/images/icon/googleTranslation.png"
import { getEmergency } from "./Api";
export const EmergencyModal = () => {
    const [emergency,setEmergency] = useState()
    const getEmergencyFn = () => {
        getEmergency().then((resp) => {
            setEmergency(resp)
          console.log(resp, "tessss");
        });
    }
    useEffect(() => {
       getEmergencyFn()

      }, []);
    return (
       <div className="emergency-wrap">
        <p className="heading">For emergency, call</p>
        <div className="number-wrap">
            <div className="flex justify-between items-start gap-2">
                <div className="blk">
                     <p className="type">Emergency</p>
                     <p className="number">119</p>

                </div>
                <div className="blk">
                <p className="type">Police</p>
                     <p className="number">112</p>
                </div>
            </div>
        </div>
        <div className="number-wrap blk-2">
            <div className="blk">
                <div className="details">
                <p className="hotline-number">1330</p>
                <p className="heading">Travel Hotline</p>
                <p className="note">Please use these contacts in case of emergency or if you need help.</p>
                </div>
                <div className="translation-wrap flex items-start">
                    <img src={googleTranslation} alt="google" />
                    <p className="note">(Korean, English, Japanese, Chinese, Russian, Vietnamese, Thai, Malay)</p>
                </div>
            </div>
        </div>
       </div>
    )
}
import { MainLayout } from "../../components/MainLayout";
import React, { useState } from "react";
import southKorea from "../../assets/images/placesIcon/southkorea.png";
import headerAction from "../../assets/images/icon/headerAction.png";
import calendar from "../../assets/images/icon/calendar.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../components/authentication";
import locationIcon from "../../assets/images/icon/location.png";
import exploreglobe from "../../assets/images/icon/exploreglobe.png";
import places from "../../assets/images/icon/activity/places-active.png";
import events from "../../assets/images/icon/activity/events-active.svg";
import food from "../../assets/images/icon/activity/local_dining.png";
import tips from "../../assets/images/icon/tips.png";
import emergency from "../../assets/images/icon/emergency.png";
import payments from "../../assets/images/icon/payments.png";
import dots from "../../assets/images/icon/dots.png";
import { Progress } from "./ProgressBar";
import Modal from "../../components/Modal";
import noImage from "../../assets/images/icon/no-image.png";

import { TipModal } from "./TipModal";
import { EmergencyModal } from "./EmergencyModal";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Spinner from "../../components/Spinner";
import { useEffect } from "react";
// import { getPlaces } from "../Activities/api";
import ScrollableModal from "../../components/CustomModal/ScrollableModal";
import { DetailsLocationPopup } from "./DetailsLocationPopup";
import { getTripDetails, getTripItinerary, updateTripDates } from "./Api";
import { TripPlanModal } from "../Activities/TripPlanModal";
import { API_URL } from "../../utils/config";

export const TripDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { authUser, isLoading, isLoadingUser } = useAuth();

  const [selectedId, setSelectedId] = useState();

  const [isActive, setIsActive] = useState(1);

  // const [openDetails, setOpenDetails] = useState(false);
  const [openTips, setOpenTips] = useState(false);
  const [openEmergency, setOpenEmergency] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [startDateSubmit, setStartDateSubmit] = useState(new Date());
  const [endDateSubmit, setEndDateSubmit] = useState(null);
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start)
    setEndDate(end)
    const date = new Date(start);
    const year = date?.getFullYear();
    const month = String(date?.getMonth() + 1)?.padStart(2, '0');
    const day = String(date?.getDate())?.padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    const dateEnd = new Date(end);
    const yearEnd = dateEnd?.getFullYear();
    const monthEnd = String(dateEnd?.getMonth() + 1)?.padStart(2, '0');
    const dayEnd = String(dateEnd?.getDate())?.padStart(2, '0');
    const formattedDateEnd = `${yearEnd}-${monthEnd}-${dayEnd}`;
    setStartDateSubmit(formattedDate);
    setEndDateSubmit(formattedDateEnd);

  };

  const [getDataOfTrip, setDataOfTrip] = useState();
  const [openPlanModal, setOpenPlanModal] = useState(false);
  const [itineraryData, setItineraryData] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "unset";
  };

  const openPlanModalFn = () => {
    document.body.style.overflow = "hidden";
    setOpenPlanModal(true);
  };

  const closePlanModalFn = () => {
    setOpenPlanModal(false);
    document.body.style.overflow = "unset";
  };

  useEffect(() => {
    let data = new FormData();
    let tripdata = new FormData();

    data.append("id", location?.state?.data);
    tripdata.append("trip_id", location?.state?.data);

    getTripDetails(data).then((resp) => {
      setDataOfTrip(resp);
    });
    getTripItinerary(tripdata).then((resp) => {
      setItineraryData(resp);
    });
  }, []);
const updateTripDateFn = () => {
  let data = new FormData();
  data.append("id", location?.state?.data);
  data.append("departure_date", startDateSubmit);
  data.append("return_date", endDateSubmit);
  data.append("country_id", getDataOfTrip?.data?.country?.id);
  data.append("departure_country_id", getDataOfTrip?.data?.departure_country?.id);


   updateTripDates(data).then((resp) => {
    setOpenCalendar(false)
    
   })
}

  return (
    <>
      {!isLoading && !isLoadingUser ? (
        <MainLayout>
          <div className="trip-details-header">
            <div className="container">
              <div className="trip-header-wrap">
                <div className="flex items-center justify-between desk-only">
                  <div className="flex items-center gap-2">
                    <button
                      className="transparent-button"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      <img
                        src={headerAction}
                        width={22}
                        height={22}
                        alt="headerAction"
                      />
                    </button>
                    <img
                      src={API_URL + getDataOfTrip?.data?.country?.flag}
                      width={48}
                      height={48}
                      alt="place"
                    />
                    <p className="trip-text">
                      Trip to {getDataOfTrip?.data?.country?.name}
                    </p>
                  </div>
                  <div>
                    <button
                      className="trip-set-btn gap-2 flex items-center"
                      style={{ color: "#FAF8FF" }}
                      onClick={() => {
                        setOpenCalendar(true);
                      }}
                    >
                      <img src={calendar} alt="calendar" /> Set Date
                    </button>
                  </div>
                </div>
                <div className="flex items-center justify-between mob-only">
                  <button
                    className="transparent-button"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <img
                      src={headerAction}
                      width={22}
                      height={22}
                      alt="headerAction"
                    />
                  </button>
                  <div className="header-container flex items-center justify-between">
                    <div className="flex gap-2 items-center">
                      <img
                        src={southKorea}
                        width={48}
                        height={48}
                        alt="place"
                      />
                      <p className="trip-text">
                        Trip to {getDataOfTrip?.data?.country?.name}
                      </p>
                    </div>
                    <div>
                      <button
                        className="trip-set-btn gap-2 flex items-center"
                        style={{ color: "#FAF8FF" }}
                        onClick={() => {
                          setOpenCalendar(true);
                        }}
                      >
                        <img src={calendar} alt="calendar" /> Set Date
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container trip-details">
            <div className="content-area w-100 flex justify-between">
              <div
                className={
                  isActive === 1
                    ? "sub-content1"
                    : "sub-content1  d-none-content"
                }
              >
                {itineraryData?.data?.map((ele) => (
                  <div
                    className="flex  items-center"
                    style={{ gap: 40, marginBottom: 40 }}
                  >
                    <div className="status flex flex-col items-center gap-2">
                      <div className="dots"></div>
                      <div className="line"></div>
                    </div>

                    <div className="card">
                      {/* <div className="img-wrap">
                        <img src={ele?.location?.locationimage_set[0].img} alt="img" />
                      </div> */}
                      {ele?.location?.locationimage_set.length === 0 ? (
                        <div className="img-wrap">
                          <img src={noImage} alt="places" />
                        </div>
                      ) : (
                        <div className="img-wrap">
                          <img
                            src={API_URL + ele?.location?.locationimage_set[0]}
                            alt="places"
                          />
                        </div>
                      )}
                      <div className="details">
                        <p className="property-type">
                          {ele?.location?.type?.name}
                        </p>
                        <p className="name">
                          {ele?.location?.name_of_location}
                        </p>
                        <div className="address-wrap flex justify-between items-start">
                          <p className="address">{ele?.location?.address}</p>
                          <div className="icons flex items-center gap-2">
                            <div className="">
                              <button
                                className="secondary-button view-details-btn w-100"
                                onClick={() => {
                                  // setOpenDetails(true);
                                  openModal();
                                  setSelectedId(ele?.location?.id);
                                  document.body.style.overflow = "hidden";
                                }}
                              >
                                View Details
                              </button>
                            </div>
                            <div className="">
                              <button
                                className="transparent-button"
                                onClick={() => {
                                  window.open(
                                    ele?.location?.google_map,
                                    "_blank"
                                  );
                                }}
                              >
                                <img src={locationIcon} alt="location" />
                              </button>
                            </div>
                            <div className="">
                              <button className="transparent-button">
                                <img src={dots} alt="dots" />
                              </button>
                            </div>
                          </div>
                        </div>

                        <p className="entry">
                          {ele?.location?.cost_description}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* ))}  */}
              <div
                className={
                  isActive === 2
                    ? "sub-content2"
                    : "sub-content2 d-none-content"
                }
              >
                <div className="recommendations-blk blk">
                  <div className="flex gap-2">
                    <img src={exploreglobe} alt="explore" />
                    <p className="title">More recommendations</p>
                  </div>

                  <p className="recommendation-note">
                    Don't miss out on these places in Japan
                  </p>
                  <div className="category-wrap">
                    <div
                      className="category-blk"
                      onClick={() => {
                        navigate("/activities/places");
                      }}
                    >
                      <img src={places} alt="places" />
                      <p>Places</p>
                    </div>
                    <div
                      className="category-blk"
                      onClick={() => {
                        navigate("/activities/events");
                      }}
                    >
                      <img src={events} alt="events" />
                      <p>Events</p>
                    </div>
                    <div
                      className="category-blk"
                      onClick={() => {
                        navigate("/activities/food-and-drinks");
                      }}
                    >
                      <img src={food} alt="food" />
                      <p>Food & Drinks</p>
                    </div>
                    <div
                      className="category-blk"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOpenTips(true);
                        document.body.style.overflow = "hidden";
                      }}
                    >
                      <img src={tips} alt="tips" />
                      <p>Tips</p>
                    </div>
                    <div
                      className="category-blk"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOpenEmergency(true);
                        document.body.style.overflow = "hidden";
                      }}
                    >
                      <img src={emergency} alt="emergency" />
                      <p>Emergency</p>
                    </div>
                  </div>
                </div>
                <br />
                <div className="blk spending-blk">
                  <div className="flex gap-2">
                    <img src={payments} alt="payments" />
                    <p className="title">Your spendings</p>
                  </div>

                  <p className="total_spending">$ 1,200</p>
                  <div className="status-bar">
                    <Progress done={30} />
                  </div>
                  <div className="left-spending">
                    <p className="left-text">
                      Left to spend &nbsp; &nbsp;
                      <span className="grey-text">$ 18,800</span>
                    </p>
                  </div>
                  <p className="total-grey-text">$ 20,000</p>
                </div>
              </div>
            </div>
            <ScrollableModal isOpen={isModalOpen} onClose={closeModal}>
              <div className="scrollable-content">
                <DetailsLocationPopup selectedId={selectedId} />

                {/* Content that exceeds the height of the modal */}
              </div>
              <div className="footer-details">
                <button
                  className="primary-button"
                  onClick={() => {
                    if (authUser) {
                      openPlanModalFn();
                    } else {
                      document.body.style.overflow = "unset";
                      navigate("/login");
                    }
                  }}
                >
                  Add to Itinerary
                </button>
              </div>
            </ScrollableModal>
            {openPlanModal && (
              <Modal
                isOpen={openPlanModalFn}
                onClose={setOpenPlanModal}
                // title={"Tips"}
                className={"plan-modal"}
              >
                <div className="Modal-custom">
                  <TripPlanModal
                    id={selectedId}
                    closePlanModalFn={closePlanModalFn}
                    closeModal={closeModal}
                  />
                </div>
              </Modal>
            )}
            <Modal
              isOpen={openTips}
              onClose={setOpenTips}
              // title={"Tips"}
              className={"tipsPopup"}
            >
              <div className="Modal-custom">
                <TipModal />
              </div>
            </Modal>
            <Modal
              isOpen={openEmergency}
              onClose={setOpenEmergency}
              title={"Emergency"}
              className={"emergency-modal"}
            >
              <div className="Modal-custom">
                <EmergencyModal />
              </div>
            </Modal>
            <Modal
              isOpen={openCalendar}
              onClose={setOpenCalendar}
              title={"Select your departure and return date"}
              className={"calendar-modal"}
            >
              <div>
                <div className="calendar-body">
                  <DatePicker
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    selectsDisabledDaysInRange
                    inline

                  />
                </div>
                <div className="footer-calendar">
                  <button className="primary-button" onClick={() => {
                    updateTripDateFn()
                  }}>Save Dates</button>
                </div>
              </div>
            </Modal>
          </div>
          <div className="mob-trip-tabs">
            <div className="flex">
              <div
                className={
                  isActive === 1 ? "trip-tab" : "trip-tab trip-tab--inactive"
                }
                onClick={() => {
                  setIsActive(1);
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_144_8246"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  >
                    <rect width="24" height="24" fill="#D9D9D9" />
                  </mask>
                  <g mask="url(#mask0_144_8246)">
                    <path
                      d="M8.50252 14.04C8.96126 14.04 9.35312 13.8792 9.67812 13.5575C10.0031 13.2359 10.1656 12.8492 10.1656 12.3975C10.1656 11.9458 10.0023 11.56 9.67561 11.24C9.34893 10.92 8.95622 10.76 8.49748 10.76C8.03874 10.76 7.64687 10.9208 7.32188 11.2425C6.99687 11.5641 6.83437 11.9508 6.83437 12.4025C6.83437 12.8542 6.99771 13.24 7.32439 13.56C7.65107 13.88 8.04378 14.04 8.50252 14.04ZM8.5 19.76C10.1385 18.28 11.3471 16.942 12.1258 15.746C12.9044 14.55 13.2937 13.488 13.2937 12.56C13.2937 11.1067 12.8333 9.93 11.9125 9.03C10.9917 8.13 9.85417 7.68 8.5 7.68C7.14583 7.68 6.00833 8.13 5.0875 9.03C4.16667 9.93 3.70625 11.1067 3.70625 12.56C3.70625 13.488 4.09557 14.55 4.87422 15.746C5.65286 16.942 6.86146 18.28 8.5 19.76ZM8.5 22C6.31979 20.1467 4.69141 18.4467 3.61484 16.9C2.53828 15.3533 2 13.908 2 12.564C2 10.548 2.65 8.95 3.95 7.77C5.25 6.59 6.76667 6 8.5 6C10.2333 6 11.75 6.59 13.05 7.77C14.35 8.95 15 10.548 15 12.564C15 13.908 14.4617 15.3533 13.3852 16.9C12.3086 18.4467 10.6802 20.1467 8.5 22Z"
                      fill="#6C27FF"
                    />
                    <path
                      d="M8 20.8132C11.5322 21.093 18.5022 21.369 18.8846 19.1641C19.3453 16.5072 18.4769 16.8911 15.3716 16.0298C12.9528 15.3589 13.7014 12 15.3716 12C17.0417 12 17.9055 12 18.3663 12"
                      stroke="#6C27FF"
                      stroke-dasharray="1 1"
                    />
                    <path
                      d="M17.9999 11.625C16.7638 10.5833 15.8437 9.61111 15.2395 8.70833C14.6353 7.80556 14.3333 6.97222 14.3333 6.20833C14.3333 5.05556 14.7048 4.13889 15.4478 3.45833C16.1909 2.77778 17.0416 2.4375 17.9999 2.4375C18.9583 2.4375 19.8089 2.77778 20.552 3.45833C21.2951 4.13889 21.6666 5.05556 21.6666 6.20833C21.6666 6.97222 21.3645 7.80556 20.7603 8.70833C20.1562 9.61111 19.236 10.5833 17.9999 11.625ZM17.9999 7.04167C18.2777 7.04167 18.5103 6.94792 18.6978 6.76042C18.8853 6.57292 18.9791 6.34028 18.9791 6.0625C18.9791 5.79861 18.8853 5.56944 18.6978 5.375C18.5103 5.18056 18.2777 5.08333 17.9999 5.08333C17.7221 5.08333 17.4895 5.18056 17.302 5.375C17.1145 5.56944 17.0208 5.79861 17.0208 6.0625C17.0208 6.34028 17.1145 6.57292 17.302 6.76042C17.4895 6.94792 17.7221 7.04167 17.9999 7.04167Z"
                      fill="#6C27FF"
                    />
                  </g>
                </svg>
              </div>
              <div
                className={
                  isActive === 2 ? "trip-tab" : "trip-tab trip-tab--inactive"
                }
                onClick={() => {
                  setIsActive(2);
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.6">
                    <mask
                      id="mask0_144_8242"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    >
                      <rect width="24" height="24" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_144_8242)">
                      <path
                        d="M5.07166 11.0001C4.44602 11.0001 3.91045 10.7774 3.46493 10.3319C3.0194 9.88634 2.79663 9.35077 2.79663 8.72515V5.0719C2.79663 4.44627 3.0194 3.91069 3.46493 3.46517C3.91045 3.01964 4.44602 2.79688 5.07166 2.79688H8.72491C9.35052 2.79688 9.8861 3.01964 10.3316 3.46517C10.7771 3.91069 10.9999 4.44627 10.9999 5.0719V8.72515C10.9999 9.35077 10.7771 9.88634 10.3316 10.3319C9.8861 10.7774 9.35052 11.0001 8.72491 11.0001H5.07166ZM5.07166 21.2034C4.44602 21.2034 3.91045 20.9807 3.46493 20.5351C3.0194 20.0896 2.79663 19.554 2.79663 18.9284V15.2751C2.79663 14.6495 3.0194 14.114 3.46493 13.6684C3.91045 13.2229 4.44602 13.0001 5.07166 13.0001H8.72491C9.35052 13.0001 9.8861 13.2229 10.3316 13.6684C10.7771 14.114 10.9999 14.6495 10.9999 15.2751V18.9284C10.9999 19.554 10.7771 20.0896 10.3316 20.5351C9.8861 20.9807 9.35052 21.2034 8.72491 21.2034H5.07166ZM15.2749 11.0001C14.6493 11.0001 14.1137 10.7774 13.6682 10.3319C13.2227 9.88634 12.9999 9.35077 12.9999 8.72515V5.0719C12.9999 4.44627 13.2227 3.91069 13.6682 3.46517C14.1137 3.01964 14.6493 2.79688 15.2749 2.79688H18.9282C19.5538 2.79688 20.0894 3.01964 20.5349 3.46517C20.9804 3.91069 21.2032 4.44627 21.2032 5.0719V8.72515C21.2032 9.35077 20.9804 9.88634 20.5349 10.3319C20.0894 10.7774 19.5538 11.0001 18.9282 11.0001H15.2749ZM15.2749 21.2034C14.6493 21.2034 14.1137 20.9807 13.6682 20.5351C13.2227 20.0896 12.9999 19.554 12.9999 18.9284V15.2751C12.9999 14.6495 13.2227 14.114 13.6682 13.6684C14.1137 13.2229 14.6493 13.0001 15.2749 13.0001H18.9282C19.5538 13.0001 20.0894 13.2229 20.5349 13.6684C20.9804 14.114 21.2032 14.6495 21.2032 15.2751V18.9284C21.2032 19.554 20.9804 20.0896 20.5349 20.5351C20.0894 20.9807 19.5538 21.2034 18.9282 21.2034H15.2749ZM5.07166 8.72515H8.72491V5.0719H5.07166V8.72515ZM15.2749 8.72515H18.9282V5.0719H15.2749V8.72515ZM15.2749 18.9284H18.9282V15.2751H15.2749V18.9284ZM5.07166 18.9284H8.72491V15.2751H5.07166V18.9284Z"
                        fill="#1D005C"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </MainLayout>
      ) : (
        ""
      )}
      <Spinner loading={isLoading || isLoadingUser} />
    </>
  );
};

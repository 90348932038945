import Axios from 'axios';
import toast from 'react-hot-toast';

import { API_URL } from './config';
import storage from './storage';

function authRequestInterceptor(config) {
  const token = storage.getToken();
  if (token) {
    config.headers.authorization = ` Bearer ${token}`;
  }
  config.headers.Accept = 'application/json';
  return config;
}

export const axios = Axios.create({
  baseURL: API_URL,
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => {
    return response?.data;
  },
  (error) => {
    const token = storage.getToken();
    if (token) {
      if (error.response.status === 401) {
        storage.clearToken();
        window.location.assign(window.location.origin);
      }
    }
    const message = error?.response?.data?.message?.email || error?.response?.data?.message || error?.message  ;
      console.log(error)
      toast.error(message);
    

    return Promise.reject(error);
  }
);

// window.axios = axios;

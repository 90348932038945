import { Link, useNavigate } from "react-router-dom";

import logo from "../../assets/images/logo/logo.png";
import success from "../../assets/images/icon/success/success.svg";

export const Success = () => {
  const navigate = useNavigate();

  return (
    <>
      <div>
        <div className="header r-success-header">
          <div className="flex items-center justify-between">
            <button
              className="flex items-center logo-wrap transparent-button"
              onClick={() => {
                navigate("/");
              }}
            >
              <img src={logo} alt="logo" className="logo" />
              <p class="logoText">trundle</p>
            </button>
          </div>
        </div>
        <div className="success-box">
          <div className="r-success-box">
            <div className="r-itinerary-saved">
              <div>
                <img src={success} alt="success" className="success-star" />
              </div>
              <div className="r-saved-succesfully-div">
                <p className="r-saved-succesfully-text">
                  Your itinerary saved{" "}
                </p>
                <p className="r-saved-succesfully-text">successfully</p>
              </div>
            </div>
            <div className="r-sim-visa-div">
              <div className="r-sim-div">
                <p className="sim-heading">Need a Sim card at South Korea?</p>
                <p className="sim-text">
                  The app will guide and ease your process of getting a sim card
                  or an e-sim for the trip
                </p>
                <Link to="/">
                  <span className="r-guideme">Guide Me</span>
                </Link>
              </div>
              <div className="r-visa-div">
                <p className="sim-heading">Need help in getting VISA?</p>
                <p className="sim-text">
                  You need a single entry visa (valid for 90days) as tourist for
                  South Korea, unless you are from one of the visa-free
                  countries.
                </p>
                <Link to="/">
                  <span className="r-guideme">Guide Me</span>
                </Link>
              </div>
            </div>
            <div className="r-corona">
              <p className="sim-heading">Corona Virus Restrictions</p>
              <p className="sim-text">
                Due to the security measures against the COVID-19 Coronavirus
                taken by the Japanese authorities, there are some travel
                restrictions that may affect you.
              </p>
              <Link to="/">
                <span className="r-knowmore">Know More</span>
              </Link>
            </div>
          </div>
          <div className="r-go-to-mytrip">Go to My Trip</div>
        </div>
      </div>
    </>
  );
};

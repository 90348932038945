import { useNavigate } from "react-router-dom";
import noTrip from "../../assets/images/icon/emptystateTrip.png";


export const NotSignedUpModal = ({closeSignupModalFn}) => {
  const navigate = useNavigate()
  const signupFn = () => {
    let products = JSON.parse(localStorage.getItem("cart")) || [];
    localStorage.setItem("itinerary", JSON.stringify(products));
    // localStorage.removeItem("cart")
    closeSignupModalFn()
    navigate("/signup")
  };
  return (
    <div className="plan-modal not-sign-up-modal">
      <div className="plan-body">
        <div className="flex flex-col justify-center items-center">
          <img src={noTrip} alt="notrip" />
          <p className="not-sign-up">You've not signed up</p>
          <p className="second-text">
            Please create a profile to save your itinerary
          </p>
          <br />
          <div className="not-sign-up-wrap">
          <button
            className="primary-button"
            onClick={() => {
              signupFn();
            }}
          >
            Sign Up
          </button>
          </div>
        </div>
      </div>
    </div>
  );
};

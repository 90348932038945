import { useForm } from "react-hook-form";
import alert from "../../assets/images/icon/alert.png";
import { InputField } from "../../components/InputField";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAuth } from "../../components/authentication";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";


const schema = yup.object().shape({
  email: yup.string().email().required(),
});
export const LoginByEmail = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({ resolver: yupResolver(schema), mode: "onChange" });
  const navigate = useNavigate();
  const { authUser } = useAuth();

  useEffect(() => {
    if (authUser) {
      return navigate("/");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);
  const {resendOTP} = useAuth()
  const onSubmit = (datas, e) => {

    var formdata = new FormData();
    formdata.append("email", datas?.email);
    resendOTP(formdata, datas)
  };
  const onError = (errors, e) => console.log(errors, e, "lll");

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)} className="form-signup">
        <div className="form-control">
          <InputField
            placeholder="sam@gmail.com"
            type="text"
            name="email"
            label="Email"
            /* register your input into the hook by invoking the "register" function */
            register={register}
          />
          {/* include validation with required or other standard HTML validation rules */}
          {/* errors will return when field validation fails  */}
          <p className="text-danger">{errors?.email && errors.email.message}</p>
        </div>

        <div className="alert-wrap">
          <div className="alert-container">
            <div className="alert">
              <img src={alert} alt="alert-icon" />
              <p>
                We’ll send you an OTP to your Email Id to complete the
                registration.{" "}
              </p>
            </div>
            <div className="button-wrap">
              {/* isdirty and isValid depends upon onChange method of input box */}
              <button
                className="primary-button button-signup"
                type="submit"
                disabled={!isDirty || !isValid}
              >
                Send OTP
              </button>
            </div>
          </div>
        </div>
      </form>
    
    </>
  );
};

import React from "react";
// import { filters } from "../../utils/filters";
import { useEffect } from "react";
import { getFilters } from "../../pages/Activities/api";
import { useState } from "react";
const Modal = (props) => {
  const divStyle = {
    display: props.displayModal ? "flex" : "none",
  };
  const [filters, setFilters] = useState()
  function closeModal(e) {
    e.stopPropagation();
    props.closeModal();
    document.body.style.overflow = 'unset';
  }
  useEffect(() => {
    getFilters( props?.countryId === undefined ? "" :`country_id=${props?.countryId}`).then((resp) => {
      setFilters(resp?.data)
    })
  },[props?.countryId])
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionClick = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((selectedOption) => selectedOption !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }

  };
  const applyFilters = () => {
    const selectedOptionsAsString = selectedOptions.join(","); 
    props?.setInterest(selectedOptionsAsString)
    props.closeModal();
    document.body.style.overflow = 'unset';
  }

  return (
    <div className="filter-modal"  style={divStyle}
    onClick={(e) => {
      // close modal when outside of modal is clicked
      closeModal(e);
    }}
    >
      <div className="modal-content"  onClick={(e) => {
          // do not close modal if anything inside modal content is clicked
          e?.stopPropagation();
        }}>
         <div className="content-blk">
        <div className="handle-wrap">
          <div className="handle"></div>
        </div>
        <p className="heading text-center main-heading">Filter</p>
        <div className="sub-content">
          {/* <div className="filter-blk">
            <p className="heading">Cities</p>
            <div className="filters-btn-wrap">
              {filters?.Cities?.map((ele) => {
                console.log("eleeee", ele)
                return (
                <button className="filter-btn">{ele?.name}</button>
              )})}
            </div>
          </div> */}
          {/* <div className="filter-blk">
            <p className="heading">Entry</p>
            <div className="filters-btn-wrap">
              {filters?.entry?.map((ele) => (
                <button className="filter-btn">{ele?.entry}</button>
              ))}
            </div>
          </div> */}
          <div className="filter-blk">
            <p className="heading">Interests</p>
            <div className="filters-btn-wrap">
              {filters?.Interests?.map((option) => (
                <button
                key={option}
                className={`filter-btn ${selectedOptions.includes(option?.id) ? 'active' : ''}`}
                onClick={() => handleOptionClick(option?.id)}
                >{option?.name}</button>
              ))}
            </div>
          </div>
          {/* <div className="filter-blk">
            <p className="heading">Open During</p>
            <div className="filters-btn-wrap">
              {filters?.openDuring?.map((ele) => (
                <button className="filter-btn">{ele?.slot}</button>
              ))}
            </div>
          </div> */}
          </div>

        </div>
        <div className="flex justify-center apply-btn">
          <button className="primary-button" onClick={() => {
            applyFilters()
          }}>Apply</button>
          </div>
        
      </div>
    </div>
  );
};
export default Modal;

import { useState, useEffect } from 'react';
import { DOTS, usePaginationRange } from '../../hooks/usePaginationRange';
// import { axios } from '../../utils/axios';


const PaginationCustom = ({
  data,
  setCurrentPage,
  currentPage,
  buttonConst,
  contentPerPage,
  setDataOfPlaces,
  siblingCount,
  section
}) => {
  const [totalPageCount] = useState(Math.ceil(data?.count / contentPerPage));

  const paginationRange = usePaginationRange({
    totalPageCount,
    contentPerPage,
    buttonConst,
    siblingCount,
    currentPage,
  });

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: '0px',
    });
  }, [currentPage]);

  function goToNextPage() {
    setCurrentPage((page) => page + 1);
    // const urlParams = section === "places" ? "get-places" : section === "events" ? "get-events" : "get-food-drinks" 
    // axios.get(`https://api.trundle.world/api-v1/location/${urlParams}?page=${currentPage + 1}`).then((resp) =>{
    //   setDataOfPlaces(resp?.data)
    // })
  }
  function gotToPreviousPage() {
    // const urlParams = section === "places" ? "get-places" : section === "events" ? "get-events" : "get-food-drinks" 

    // axios.get(`https://api.trundle.world/api-v1/location/${urlParams}?page=${currentPage-1}`).then((resp) =>{
    //   setDataOfPlaces(resp?.data)
    // })
    setCurrentPage((page) => page - 1);
  }
  function changePage(event) {
    // const urlParams = section === "places" ? "get-places" : section === "events" ? "get-events" : "get-food-drinks" 

    const pageNumber = Number(event.target.textContent);
    // axios.get(`https://api.trundle.world/api-v1/location/${urlParams}?page=${pageNumber}`).then((resp) =>{
    //   setDataOfPlaces(resp?.data)
    // })
    setCurrentPage(pageNumber);
  }

  return (
    <div>
      {/* show the pagiantion
                it consists of next and previous buttons
                along with page numbers, in our case, 5 page
                numbers at a time */}
      <div
        className="pagination flex"
        style={{
          justifyContent: 'center',
          alignItems: 'flex-start',
        }}
      >
        {/* previous button */}
        <button
          onClick={gotToPreviousPage}
          disabled={currentPage === 1}
          //   className={` prev ${currentPage === 1 ? 'disabled' : ''}`}
          className={
            currentPage > 1
              ? 'prev-btn'
              : 'prev-btn'
          }
          style={currentPage > 1 ? { cursor: 'pointer' } : { cursor: 'auto' }}
        >
          <span className="sr-only">Previous</span>
          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        {/* show paginated button group */}
        {paginationRange?.map((item, index) => {
          if (item === DOTS) {
            return (
              <button key={index} className={`paginationItem dots-pagination`}>
                &#8230;
              </button>
            );
          }
          return (
            <button
              key={index}
              onClick={changePage}
              className={`paginationItem ${currentPage === item ? 'active' : null}`}
            >
              <span
                className={
                  'border-gray-300 active:bg-gray-50 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium' +
                  (item === currentPage ? ' bg-gray-50' : '')
                }
              >
                {item}
              </span>
            </button>
          );
        })}
        {/* next button */}
        <button
          onClick={goToNextPage}
          disabled={currentPage === totalPageCount}
          //   className={`next ${currentPage === totalPageCount ? 'disabled' : ''}`}
          className={
            currentPage !== totalPageCount
              ? 'next-btn'
              : 'next-btn'
          }
          style={currentPage < totalPageCount ? { cursor: 'pointer' } : { cursor: 'auto' }}
        >
          <span className="sr-only">Next</span>
          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <br />
      {/* <p className="text-sm text-center text-gray-700">
        Showing
        <span className="font-medium"> {data?.data && data?.data.length} </span>
        of
        <span className="font-medium"> {data.count} </span>
        results
      </p> */}
    </div>
  );
};

export default PaginationCustom;

import userProfile from "../../../assets/images/icon/userProfile.png";
import help from "../../../assets/images/icon/help.png";
import arrow from "../../../assets/images/icon/arrow.svg";
import logout from "../../../assets/images/icon/logout.png";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../components/authentication";
import { useEffect } from "react";

export const Profile = () => {
  const navigate = useNavigate();
  const { authUser } = useAuth();
  useEffect(() => {
    if (!authUser) {
      navigate("/login");
    }
  }, []);
  return (
    <div className="profile-page-mob">
      <div className="profile-menu">
        <div className="sub-content-1">
          <div className="content-wrap">
            <p className="text-center profile-heading">Profile</p>

            <img src={authUser?.photo ? authUser?.photo : userProfile} alt="userProfile" className="profile-img" />
            <p className="username">{authUser?.first_name + authUser?.last_name} </p>
            <p className="email">{authUser?.email}</p>
            <div className="button-wrap">
              <button
                className="secondary-button"
                onClick={() => {
                  //   setDisplayModal(!displayModal);
                  //   setIsEdit(!isEdit);
                  navigate("/edit");

                  document.body.style.overflow = "hidden";
                }}
              >
                Edit Profile
              </button>
            </div>
          </div>
        </div>

        <div className="sub-content-2">
          <div className="content-wrap">
            <ul>
              <li>
                <button
                  className="transparent-button w-100 flex items-center justify-between"
                  onClick={() => {
                    // setDisplayHelpModal(!displayHelpModal);
                    // setIsHelp(!isHelp);
                    // document.body.style.overflow = "hidden";
                    navigate("/help");
                  }}
                >
                  <div className="flex items-center gap-2">
                    <img src={help} alt="help" />
                    <p>Help</p>
                  </div>
                  <div className="arrow">
                    <img src={arrow} alt="arrow" />
                  </div>
                </button>
              </li>
              <li>
                <button
                  className="transparent-button w-100 flex items-center justify-between"
                  onClick={() => {
                    // handleLogout();
                    // setOpenProfileMenu(false);
                  }}
                >
                  <div className="flex items-center gap-2">
                    <img src={logout} alt="help" />
                    <p>Log out</p>
                  </div>
                  <div className="arrow">
                    <img src={arrow} alt="arrow" />
                  </div>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

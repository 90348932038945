import { axios } from "../../../utils/axios";

export const getPlaces = (data) => {
  return axios.get(`/api-v1/location/get-places?${data}`);
};
export const getPlacesDetails = (data) => {
  return axios.post(`/api-v1/location/get-location`, data);
};
export const getEvents = (data) => {
  return axios.get(`/api-v1/location/get-events?${data}`);
};
export const getFoodAndDrinks = (data) => {
  return axios.get(`/api-v1/location/get-foods-drinks?${data}`);
};
export const getFilters = (data) => {
  return axios.get(`/api-v1/location/get-places-filter?${data}`);
};
export const addItinerary = (data) => {
  return axios.post(`/api-v1/itinerary/add-itinerary`, data);
};
export const getEventsDetails = (data) => {
  return axios.post(`/api-v1/location/get-location`, data);
};

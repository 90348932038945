import { TabsComponent } from "../../components/Tab/TabsComponent";
import tipImg from "../../assets/images/icon/tip.png";
// import activityImg from "../../assets/images/icon/activity.png";
import volume from "../../assets/images/icon/volume_up.png";

import googleTranslation from "../../assets/images/icon/googleTranslation.png";
import {  getLearnLanguage, getTip } from "./Api";
import { useEffect, useState } from "react";
import Spinner from "../../components/Spinner";

export const TipModal = () => {
  const [tipData, setTipData] = useState();
  const [langData, setLangData] = useState();
  const [isLoading, setIsLoading] = useState(false)
  const getLearnFn = () => {
    setIsLoading(true)
    getLearnLanguage().then((resp) => {
      setIsLoading(false)
      setLangData(resp)
    });
  }
  // const getTipData = getTip();
  useEffect(() => {
    setIsLoading(true)
    getTip().then((resp) => {
      setTipData(resp);
      setIsLoading(false)
    });
getLearnFn()
    // getEmergency().then((resp) => {
    //   console.log(resp, "tessss");
    // });
  }, []);
  const General = () => {
    return (
      !isLoading ?
      <div className="container">
        <div className="general-tip">
          {tipData?.data?.map((ele, i) => (
            <div className="tip-blk">
              <div className="flex items-center justify-between">
                <img src={tipImg} alt="tip" />
                <p className="id"># {ele?.id}</p>
              </div>
              <p className="note">{ele?.description}</p>
            </div>
          ))}
          {/* <div className="tip-blk">
            <div className="flex items-center justify-between">
              <img src={tipImg} alt="tip" />
              <p className="id">#02</p>
            </div>
            <p className="note">Tipping is not recommended in Japan</p>
          </div>
          <div className="tip-blk activity-blk">
            <div className="flex items-center justify-between">
              <img src={activityImg} alt="tip" />
              <p className="id">#01</p>
            </div>
            <p className="note">April 19 is a public holiday in Japan</p>
          </div> */}
        </div>
      </div>
      : 
      <Spinner loading={isLoading} />
    );
  };
  const LearnLanguage = () => {
    return (
      !isLoading ?
      <div className="container">
        <div className="general-tip">
          <img src={googleTranslation} alt="google" />
          <p className="heading">Learn Korean</p>
          <p className="note">
            Memorize these basic Korean words to communicate with locals and to
            surprise them when they least expect.
          </p>
          <br />
          {langData?.data?.map((ele) => (
          <div className="blk-language flex items-center justify-between">
            <div>
              <p>{ele?.in_english}</p>
              <p>{ele?.in_language}</p>
            </div>
            <img src={volume} alt="volume" />
          </div>
          ))}
          {/* <div className="blk-language flex items-center justify-between">
            <div>
              <p>Nice to meet you</p>
              <p>반갑습니다 (bangapseumnida)</p>
            </div>
            <img src={volume} alt="volume" />
          </div>
          <div className="blk-language flex items-center justify-between">
            <div>
              <p>Thank you</p>
              <p>감사합니다 (gamsahamnida)</p>
            </div>
            <img src={volume} alt="volume" />
          </div> */}
        </div>
      </div>
      : 
      <Spinner loading={isLoading} />

    );
  };
  const tabItems = [
    {
      id: 1,
      title: "General",
      content: <General />,
    },
    {
      id: 2,
      title: "Learn Language",
      content: <LearnLanguage />,
    },
  ];
  return (
    <div className="flex flex-col">
      <div className="tipmodalHeader">
        <div className="flex justify-center items-center">
          <p className="title">Tips</p>
        </div>
      </div>
      <div className="tab-wrap">
        <TabsComponent tabItems={tabItems} />
      </div>
    </div>
  );
};

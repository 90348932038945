import home from "../../assets/images/icon/home.svg";

import homeActive from "../../assets/images/icon/home-active.png";
import trip from "../../assets/images/icon/trip.png";

import tripActive from "../../assets/images/icon/trip-active.svg";
import bookmark from "../../assets/images/icon/bookmark.svg";

import bookmarkAdded from "../../assets/images/icon/bookmark_added.svg";
import accountActive from "../../assets/images/icon/accountActive.svg";
import account from "../../assets/images/icon/account.svg";
import { useLocation, useNavigate } from "react-router-dom";

export const BottomTabs = ({onSelectMenu, selectedMenu}) => {
    const navigate = useNavigate();
const location = useLocation()
    return (
        <ul className="flex justify-between">
        <li>
          <button
            onClick={() => {
              onSelectMenu(1);
              navigate("/")

            }}
            className={
              selectedMenu === 1 && location?.pathname === "/" ? "activeMenu menu-list" : "menu-list"
            }
          >
            <img src={ selectedMenu === 1 && location?.pathname === "/" ? homeActive : home} alt="home" />
            Home
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              onSelectMenu(2);
              navigate("/my-trip")

            }}
            className={
              location?.pathname === "/my-trip" ? "activeMenu menu-list" : "menu-list"
            }
          >
            <img src={location?.pathname === "/my-trip" ? tripActive : trip} alt="trip" />
            My Trips
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              onSelectMenu(3);
            }}
            className={
              selectedMenu === 3 ? "activeMenu menu-list" : "menu-list"
            }
          >
            <img
              src={selectedMenu === 3 ? bookmarkAdded : bookmark}
              alt="bookmark"
            />
            Bookmarks
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              onSelectMenu(4);
              navigate("/profile")
            }}
            className={
              location?.pathname === "/profile" ? "activeMenu menu-list" : "menu-list"
            }
          >
            <img
              src={  location?.pathname === "/profile" ? accountActive : account}
              alt="profile"
            />
            Profile
          </button>
        </li>
      </ul>
    )
}
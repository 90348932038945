import alert from "../../assets/images/background/alert-popup.svg";
import Select from "react-select";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getCountries } from "../SignUp/api";

const schema = Yup.object().shape({
  country: Yup.object().shape({
    label: Yup.string().required("Please select an option"),
    value: Yup.string().required("Please select an option"),
  }),
});

export const Locationpopup = ({ setDisplayModal, setDisplayCountryModal, setDepCounrtyId }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {

    setDisplayModal(false);
    document.body.style.overflow = "hidden";

    // setCounrtyId(data?.country?.id)
    setDisplayCountryModal(true);
    
  };

  const handleSelectChange = (option) => {
    setSelectedOption(option);
    setDepCounrtyId(option?.value)

    setValue("country", option); // to update form data with selected option
  };

  const isDisabled = !selectedOption || Object.keys(errors).length > 0;

  // for country dropdown
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    getCountries()
      .then((response) => {
        setCountries(response.data);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  return (
    <>
      <div className="r-location-popup">
        <p className="r-select-text">Select your country</p>
        <div className="r-relative-reuse">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <Select
                {...register("country")}
                value={selectedOption}
                onChange={handleSelectChange}
                options={countries.map((country) => ({
                  value: country.id,
                  label: country.name,
                  flag: country.flag,
                }))}
                isSearchable={false}
                placeholder="Ex: United States"
                getOptionLabel={(option) => (
                  <div className="r-img-label">
                    <img
                      src={option.flag}
                      alt={`${option.label} flag`}
                      className="flag-icon"
                    />
                    {option.label}
                  </div>
                )}
                getOptionValue={(option) => option.value}
                styles={{
                  option: (provided) => ({
                    ...provided,
                    display: "flex",
                    alignItems: "center",
                    fontSize: "1rem",
                    padding: "0.5rem",
                    border: "1px solid #dcdbdd",
                    borderTop: "none",
                    borderLeft: "none",
                    fontFamily: "SfProText-Regular",
                    borderRight: "none",
                    borderRadius: 0,
                  }),
                  control: (provided) => ({
                    ...provided,
                    height: "3.5rem",
                    boxShadow: "none",
                    borderRadius: "15px",
                    borderColor: "#d8d8d8",
                    fontFamily: "SfProText-Regular",
                    boxSizing: "border-box",
                    "&:hover": {
                      borderColor: "#545353",
                    },
                  }),
                }}
                aria-invalid={errors.country ? "true" : "false"}
              />
              {errors.country && (
                <p role="alert" className="error">
                  {errors.country.message}
                </p>
              )}
            </div>
            <div className="r-bottom-alert-button">
              <img
                src={alert}
                alt="Currently showing all prices in USD. Please bear with us while we're working on adding more currencies in due course."
              />
              <button
                type="submit"
                className="primary-button"
                disabled={isDisabled}
              >
                Proceed
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

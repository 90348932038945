
import { useNavigate } from "react-router-dom";
import support from "../../../assets/images/icon/support.png";

import headerAction from "../../../assets/images/icon/headerAction.png";

export const Help = () => {
    const navigate = useNavigate()
    return (
        <div className="profile-page-mob">
          <div className="profile-menu">
            <div className="sub-content-1">
              <div className="content-wrap w-100">

              <div className="flex items-center justify-between mob-title-wrap">
              <button
                className="transparent-button"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <img src={headerAction} alt="header" />
              </button>
              <div className="explore">
                <p className="text-center">Help Centre</p>
              </div>
            </div>
    
        
              </div>
            </div>
            <div className="content-wrap cancellation-policy">
          <p className="content-title">Cancellation Policy</p>
          <p className="content">
            Most of our activities come with a 24-hour free cancellation policy,
            meaning you can cancel your booking up until 24 hours before the
            activity starts to receive a full refund.
          </p>
          <p className="content">
            You'll find more information regarding your booking's cancellation
            policy on your voucher. Your booking will either be fully
            refundable, partially refundable or non-refundable depending on the
            activity provider's policy. All cancelable bookings will display a
            deadline for cancellation on the voucher.
          </p>
        </div>
        <div className="cancellation-policy-footer footer">
          <div className="flex items-start" style={{ gap: 16 }}>
            <img src={support} alt="support" />
            <div className="complaints-wrap">
              <p>Feedback or complaints about the app</p>
              <a href="mailto:care@trundle.world">care@trundle.world</a>
            </div>
          </div>
        </div>
         
          </div>
      
        </div>
    )
}
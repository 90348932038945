const storagePrefix = 'trundle_';

const storage = {
  getToken: () => {
    try {
      return JSON.parse(window.localStorage.getItem(`${storagePrefix}token`));
    } catch (error) {
      return '';
    }
  },
  setToken: (token) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
    // window.localStorage.removeItem('provider');
  },
};

export default storage;

import { Header } from "../../components/Header";
import Footer from "../../components/Footer";

import places from "../../assets/images/icon/activity/places.png";
import foodAndDrinks from "../../assets/images/icon/activity/local_dining.png";

import placesActive from "../../assets/images/icon/activity/places-active.png";
import eventsActive from "../../assets/images/icon/activity/events-active.svg";

import events from "../../assets/images/icon/activity/events.png";

import food from "../../assets/images/icon/activity/food.png";
import filter from "../../assets/images/icon/filter.png";
import headerAction from "../../assets/images/icon/headerAction.png";

import { useRef, useState } from "react";
import { ActivitiesPlaces } from "./ActivitiesPlaces";
import { FoodAndDrinks } from "./FoodAndDrinks";
import Modal from "../../components/FilterModal";
import { useLocation, useNavigate } from "react-router-dom";
import { Events } from "./Events";
// import Footer from "../../components/Footer";

export const Activities = () => {
  const ref = useRef();
  const location = useLocation();
  console.log(location, "location")

  const pathname = location?.pathname?.split("/")[2];
  const [countryId, setCountryId] = useState(location?.state?.selectedCountry);
  const [getDataOfPlaces, setDataOfPlaces] = useState();
  const [getDataOfFood, setDataOfFood] = useState();
  const [getDataOfEvents, setDataOfEvents] = useState([]);

  const [selectedMenu, setSelectedMenu] = useState(
    location?.state?.tab === "food" || pathname === "food-and-drinks"
      ? 3
      : pathname === "events"
      ? 2
      : 1
  );
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const [interest, setInterest] = useState();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const onSelectMenu = (id) => {
    if (selectedMenu !== id) {
      setSelectedMenu(id);
    } else {
      setSelectedMenu(-1);
    }
  };
  const selectModal = (info) => {
    document.body.style.overflow = "hidden";
    setOpenModal(!openModal);
  };
  const handleClick = () => {
    if (!openModal) {
      document.addEventListener("click", handleOutsideClick(), false);
    } else {
      document.removeEventListener("click", handleOutsideClick(), false);
    }
    document.body.style.overflow = "unset";
    setOpenModal((prevState) => ({
      openModal: !prevState.openModal,
    }));
  };

  const handleOutsideClick = (e) => {
    if (!ref.current.node.contains(e.target)) {
      handleClick();
    }
  };

  return (
    <>
    <div>
      <div className="activities-started" ref={ref}>
        <div
          className=""
          onClick={(e) => {
            e?.stopPropagation();
          }}
        >
          <Header
            openProfileMenu={openProfileMenu}
            setOpenProfileMenu={setOpenProfileMenu}
          />
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setOpenProfileMenu(false);
            // document.body.style.overflow = "unset";
          }}
        >
          <div className="activities-subheader">
            <div className="container">
              <div className="flex items-center mob-title-wrap explore-wrap">
                <button
                  className="transparent-button"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <img src={headerAction} alt="header" />
                </button>
                <div className="explore">
                  <p className="text-center">Explore</p>
                </div>
              </div>
              <ul className="flex items-center justify-center activities-tab">
                <li>
                  <button
                    className={selectedMenu === 1 ? "active" : ""}
                    onClick={() => {
                      onSelectMenu(1);
                      // navigate("/activities/places");
                      navigate("/activities/places", {
                        state: {
                          tab: "places",
                          selectedCountry: location?.state?.selectedCountry,
                          trip_id: location?.state?.trip_id
                        },
                      });
                    }}
                  >
                    <img src={places} alt="places" />
                    Places
                  </button>
                </li>
                <li>
                  <button
                    className={selectedMenu === 2 ? "active" : ""}
                    onClick={() => {
                      onSelectMenu(2);
                      // navigate("/activities/events");
                      navigate("/activities/events", {
                        state: {
                          tab: "events",
                          selectedCountry: location?.state?.selectedCountry,
                          trip_id: location?.state?.trip_id
                        },
                      });
                    }}
                  >
                    <img src={events} alt="events" />
                    Events
                  </button>
                </li>
                <li>
                  <button
                    className={selectedMenu === 3 ? "active" : ""}
                    onClick={() => {
                      onSelectMenu(3);
                      // navigate("/activities/food-and-drinks");
                      navigate("/activities/food-and-drinks", {
                        state: {
                          tab: "food-and-drinks",
                          selectedCountry: location?.state?.selectedCountry,
                          trip_id: location?.state?.trip_id
                        },
                      });
                    }}
                  >
                    <img src={food} alt="food" />
                    Food & Drinks
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="container">
            <div className="activities-info">
              <div className="places-count">
                <div className="flex items-center">
                  {selectedMenu === 1 ? (
                    <>
                      <img src={placesActive} alt="places" />
                      <p className="text-black">Places</p>
                    </>
                  ) : selectedMenu === 3 ? (
                    <>
                      <img src={foodAndDrinks} alt="food" />
                      <p className="text-black">Food & Drinks</p>
                    </>
                  ) : selectedMenu === 2 ? (
                    <>
                      <img src={eventsActive} alt="events" />
                      <p className="text-black">Events</p>
                    </>
                  ) : null}
                </div>
                <div className="divider"></div>

                {selectedMenu === 1 && getDataOfPlaces?.results ? (
                  <p className="text-grey">
                    Showing {getDataOfPlaces?.results?.length} of{" "}
                    {getDataOfPlaces?.count}
                  </p>
                ) : selectedMenu === 2 && getDataOfEvents?.results ? (
                  <p className="text-grey">
                    Showing {getDataOfEvents?.results?.length} of{" "}
                    {getDataOfEvents?.count}
                  </p>
                ) : getDataOfFood?.results ? (
                  <p className="text-grey">
                    Showing {getDataOfFood?.results?.length} of{" "}
                    {getDataOfFood?.count}
                  </p>
                ) : (
                  ""
                )}
              </div>
              <div className="filter">
                <button
                  className="secondary-button"
                  onClick={() => {
                    selectModal();
                  }}
                >
                  <img src={filter} alt="filter" />
                  Filter
                </button>
              </div>
            </div>
            {!openModal ? (
              <div className="filter-mob">
                <button
                  className="secondary-button"
                  onClick={() => {
                    selectModal();
                  }}
                >
                  <img src={filter} alt="filter" />
                  Filter
                </button>
              </div>
            ) : (
              ""
            )}
            <div className="content">
              {selectedMenu === 1 ? (
                <ActivitiesPlaces
                  selectedTab={location?.state?.tab}
                  setCountryId={setCountryId}
                  countryId={countryId}
                  interest={interest}
                  getDataOfPlaces={getDataOfPlaces}
                  setDataOfPlaces={setDataOfPlaces}
                />
              ) : selectedMenu === 3 ? (
                <FoodAndDrinks
                  selectedTab={location?.state?.tab}
                  setCountryId={setCountryId}
                  countryId={countryId}
                  interest={interest}
                  getDataOfPlaces={getDataOfFood}
                  setDataOfPlaces={setDataOfFood}
                />
              ) : (
                <Events
                  selectedTab={location?.state?.tab}
                  setCountryId={setCountryId}
                  countryId={countryId}
                  interest={interest}
                  getDataOfEvents={getDataOfEvents}
                  setDataOfEvents={setDataOfEvents}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        displayModal={openModal}
        closeModal={selectModal}
        countryId={countryId}
        setInterest={setInterest}
      />
      <div className="footer-mainlayout">
        <Footer />
      </div>
      </div>
    </>
  );
};

/* eslint-disable no-useless-concat */
import React from "react";

function Modal({ isOpen, onClose, children, title, className }) {
  function closeModal(e) {
    e.stopPropagation();
    onClose();
    document.body.style.overflow = "unset";
  }
  return isOpen ? (
    <div
      className={className + " " + "modal"}
      onClick={(e) => {
        // close modal when outside of modal is clicked
        closeModal(e);
      }}
    >
      <div
        className="modal-content"
        onClick={(e) => {
          e?.stopPropagation();
        }}
      >
        {title ? 
        <div className="modal-header">
          <h3 className="title">{title}</h3>
        </div>
        : ""
}
        <div className="modal-body Modal-custom">{children}</div>
      </div>
    </div>
  ) : null;
}

export default Modal;

/* eslint-disable react-hooks/exhaustive-deps */
import locationImg from "../../assets/images/icon/location_on.png";
import pass from "../../assets/images/icon/pass.png";
import PaginationCustom from "../../components/PaginationCustom.js";
import { useState } from "react";
import places from "../../assets/images/icon/places.png";

import { addItinerary, getFilters, getPlaces } from "./api";
import { useEffect } from "react";
import noImage from "../../assets/images/icon/no-image.png";

import Spinner from "../../components/Spinner";
import ScrollableModal from "../../components/CustomModal/ScrollableModal";
import { DetailsLocationPopup } from "../MyTrip/DetailsLocationPopup";
import Modal from "../../components/Modal";
import { TripPlanModal } from "./TripPlanModal";
import { useAuth } from "../../components/authentication";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

export const ActivitiesPlaces = ({
  setCountryId,
  countryId,
  interest,
  getDataOfPlaces,
  setDataOfPlaces,
}) => {
  console.log(countryId, "coun")
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  // const [openDetails, setOpenDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { authUser } = useAuth();
  const [selectedId, setSelectedId] = useState();
  const [selectedMenu, setSelectedMenu] = useState(-1);
  const [getDetailsOfLocation, setDetailsOfLocation] = useState();

  useEffect(() => {
    setIsLoading(true);
    const countryquery = !countryId ? "" : `country_id=${countryId}`;
    const typequery = !interest ? "" : `type=${interest}&`;
    const currentPageQuery = `page=${currentPage}`

    getPlaces((countryquery ? `${countryquery}&` : "") + typequery + currentPageQuery ).then(
      (resp) => {
        setDataOfPlaces(resp?.data);
        setIsLoading(false);
      }
    );
  }, [interest, countryId, selectedMenu, currentPage]);
  const [filters, setFilters] = useState();
  // const [isActive, setIsActive] = useState();
  const onSelectMenu = (id, country) => {
    setIsLoading(true);

    if (selectedMenu !== id) {
      setSelectedMenu(id);
    } else {
      setSelectedMenu(-1);
    }

    getPlaces(`country_id=${country === "all" ? "" : country}`).then((resp) => {
      setDataOfPlaces(resp?.data);
      setIsLoading(false);
      setCountryId(country === "all" ? "" : country);
    });
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openPlanModal, setOpenPlanModal] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "unset";
  };
  useEffect(() => {
    const query = `country_id=${!countryId? "" : countryId}`
    getFilters(query).then((resp) => {
      setFilters(resp?.data);
    });
  }, []);
  const openPlanModalFn = () => {
    document.body.style.overflow = "hidden";
    setOpenPlanModal(true);
  };

  const closePlanModalFn = () => {
    setOpenPlanModal(false);
    document.body.style.overflow = "unset";
  };

let products = JSON.parse(localStorage.getItem("cart")) || [];

// Function to handle adding a product to the cart
function addToItineraryFn(product) {
  if(authUser){
    setIsLoading(true);
    let data = new FormData();
    data.append("location_id", product?.id);

    data.append("trip_id", location?.state?.trip_id);
    addItinerary(data)
      .then((resp) => {
        closePlanModalFn();
        closeModal();
        // navigate("/tripplan2");
        navigate("/tripplan2", {
          state: {
            selectedCountry: countryId,
            trip_id: location?.state?.trip_id
          },
        });

        setIsLoading(false);
        toast.success(resp?.message);
      })
      .catch((err) => {
        closePlanModalFn();
        closeModal();
        setIsLoading(false);
      });
  }else{
    function isProductInCart(productId) {
      return products?.some(item => item?.location?.id === productId);
    }
    if (isProductInCart(product?.id)) {
      // console.log("Product already in cart");
      toast.error("Itinerary already added")
  closeModal();

      return;
    }
    
  // Create a "location" object for the product
  let location = {
    ...product
    // other product details
  };

  // Add the "location" object to the cart
  products.push({ location });

  // Save the updated cart to local storage
  localStorage.setItem('cart', JSON.stringify(products));
  closeModal();

navigate("/tripplan2");
  }
}

  return (
    <>
      {!isLoading ? (
        <div>
          <div className="cities-filters">
            <div className="filters-btn-wrap flex gap-2 items-center">
              <button
                className={
                  selectedMenu === -1 ? "active filter-btn" : "filter-btn"
                }
                onClick={() => {
                  onSelectMenu(-1, "all");
                }}
              >
                All
              </button>

              {filters?.Cities?.map((ele, i) => {
                return (
                  <button
                    className={
                      selectedMenu === i ? "active filter-btn" : "filter-btn"
                    }
                    onClick={() => {
                      onSelectMenu(i, ele?.Country);
                    }}
                  >
                    {ele?.name}
                  </button>
                );
              })}
            </div>
          </div>
          <br />
          {getDataOfPlaces?.results?.length === 0 ? (
            <>
              <div className="flex items-center flex-col justify-center h-100 empty-state">
                <img src={places} alt="empty" />
                <p className="empty-state-text">No places found</p>
              </div>
            </>
          ) : (
            <>
              <div className="activities-places-wrap">
                {getDataOfPlaces?.results?.map((element) => (
                  <div className="activities-places-card ">
                    {/* <div className="img-blk">
                  {element?.locationimage_set?.map((imageurl) => (
                    <img src={imageurl?.img} alt="places" />
                  ))}
                </div> */}
                    {element?.locationimage_set.length === 0 ? (
                      <div className="img-blk">
                        <img src={noImage} alt="places" />
                      </div>
                    ) : (
                      <div className="img-blk">
                        <img src={element?.locationimage_set[0]} alt="places" />
                      </div>
                    )}
                    <div className="content-blk">
                      <p className="name">{element?.name_of_location}</p>
                      <p className="property">{element?.typeOfProperty}</p>
                      <div className="sub-content-wrap">
                        <img src={locationImg} alt="location" />
                        <p className="address">{element?.address}</p>
                      </div>
                      <div
                        className="items-center sub-content-wrap"
                        style={{ alignItems: "center" }}
                      >
                        <img src={pass} alt="pass" />
                        <p className="cost">{element?.cost_description}</p>
                      </div>
                      <button
                        className="places-tile-button"
                        onClick={() => {
                          // setOpenDetails(true);
                          openModal();
                          // setIsModalOpen(true)
                          setSelectedId(element?.id);
                        }}
                      >
                        View Details
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div className="pagination-wrap">
                <PaginationCustom
                  data={getDataOfPlaces}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  buttonConst={5}
                  contentPerPage={10}
                  setDataOfPlaces={setDataOfPlaces}
                  siblingCount={1}
                  section="places"
                />
              </div>
            </>
          )}

          <ScrollableModal isOpen={isModalOpen} onClose={closeModal}>
            <div className="scrollable-content">
              <DetailsLocationPopup
                selectedId={selectedId}
                setDetailsOfLocation={setDetailsOfLocation}
              />

              {/* Content that exceeds the height of the modal */}
            </div>
            <div className="footer-details details-popup-footer">
              <button
                className="primary-button"
                onClick={() => {
                  if (authUser && !location?.state?.trip_id) {
                    openPlanModalFn();
                    closeModal()
                  } else {
                    document.body.style.overflow = "unset";
                    closeModal()
                    // navigate("/login");
                    addToItineraryFn(getDetailsOfLocation);
                  }
                }}
              >
                Add to Itinerary
              </button>
            </div>
          </ScrollableModal>
          {openPlanModal && (
            <Modal
              isOpen={openPlanModalFn}
              onClose={closePlanModalFn}
              title={"Please select"}
              className={"plan-modal"}
            >
              <div className="Modal-custom">
                <TripPlanModal
                  id={selectedId}
                  closePlanModalFn={closePlanModalFn}
                  closeModal={closeModal}
                />
              </div>
            </Modal>
          )}
        </div>
      ) : (
        <Spinner loading={isLoading} />
      )}
    </>
  );
};


import Footer from "../Footer";
import { Header } from "../Header";
import { useState } from "react";
// import { useAuth } from "../authentication";

// Layout for defining components
export const MainLayout = ({ children, className }) => {
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  // const {authUser} = useAuth()

  return (
    <div className="">
      <Header
        openProfileMenu={openProfileMenu}
        setOpenProfileMenu={setOpenProfileMenu}
      />

      <div
        className={className +  " main"}
        onClick={() => {
          setOpenProfileMenu(false);
        }}
      >
        {children}
      </div>
      <div className="footer-mainlayout">
        <Footer />
      </div>
    </div>
  );
};

import alert from "../../assets/images/background/alert-popup2.svg";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { getAvailableCountries, planTrip } from "../Home/api";
import { useState } from "react";
import Spinner from "../../components/Spinner";
import { useAuth } from "../../components/authentication";

export const Country = (props) => {
  const { handleSubmit, formState } = useForm({
    mode: "onChange",
  });

  const navigate = useNavigate();
  const {authUser} = useAuth()
  const [availableCountries, setAvailableCountries] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [countryId, setCounrtyId] = useState();
  const onSubmit = () => {
    if(authUser) {

    
    let datas = new FormData();
    datas.append("departure_country_id", props?.depCountryId);

    datas.append("country_id", countryId);

    planTrip(datas).then((resp) => {
      // setDisplayModal(false)
      props?.setDisplayCountryModal(false);
      document.body.style.overflow = "unset";

      // navigate("/planatrip");
      navigate("/planatrip", {
        state: {
          selectedCountry: countryId,
          trip_id: resp?.trip_id
        },
      });
      // setDisplayCountryModal(false);
    }).catch((error) => {
      props?.setDisplayCountryModal(false);
      document.body.style.overflow = "unset";

    })
  }else{
    document.body.style.overflow = "unset";

    const datas = {
      "departure_country_id" :  props?.depCountryId,
      "country_id" : countryId
    }
    localStorage.setItem("planTrip", JSON.stringify(datas))
    navigate("/planatrip", {
      state: {
        selectedCountry: countryId,
      },
    });
      // navigate("/planatrip");
  }
    // navigate("/tripplan2");
  };
  useEffect(() => {
    setIsLoading(true);
    getAvailableCountries().then((res) => {
      setIsLoading(false);
      setCounrtyId(res?.data[0]?.id)

      setAvailableCountries(res);
    });
  }, []);


  const isDisabled = !formState.isValid;

  return (
    <>
      <div className="r-location-popup">
        <p className="r-yourlocation-text">Where are you travelling to?</p>
        <p className="r-select-text">Select one destination</p>
        <div className="r-relative-reuse" style={{ height: "339px" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {availableCountries?.data?.map((ele, id) => (
              <div className="radio-wrapper r-border-bottom">
                <img src={ele?.flag} alt="flag" />
                <input
                  id={ele?.id}
                  class="radio-custom"
                  value={ele?.id}
                  name="radio-group"
                  type="radio"
                  defaultChecked={id === 0}
                  onClick={(e) => {
                    setCounrtyId(e.target.value);
                  }}
                />
                {/* <label>{ele?.name}</label> */}
                <label
                  for={ele?.id}
                  className="radio-custom-label country-radio flex items-center gap-2"
                >
                  {ele?.name}
                </label>
              </div>
            ))}

            <div className="r-bottom-alert-button">
              <img
                src={alert}
                alt="Currently showing all prices in USD. Please bear with us while we're working on adding more currencies in due course."
              />
              <button
                type="submit"
                className="primary-button"
                disabled={isDisabled}
              >
                Proceed
              </button>
            </div>
          </form>
        </div>
        <Spinner loading={isLoading} />
      </div>
    </>
  );
};

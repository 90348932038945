import { useState } from "react";
import { TabItemComponent } from "./TabItemComponent";

export const TabsComponent = ({tabItems}) => {
    const [active, setActive] = useState(1);
     
     return (
       <div className="wrapper">
         <div className="tabs">
           {tabItems.map(({ id, icon, title }) =><TabItemComponent
              key={title}
              icon={icon}
              title={title}
              onItemClicked={() => setActive(id)}
              isActive={active === id}
            />
         )}
         </div>
         <div className="content">
           {tabItems.map(({ id, content }) => {
             return active === id ? content : ''
           })}
         </div>
        </div>
     )
   }
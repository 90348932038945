import ClipLoader from "react-spinners/ClipLoader";

const override = {
  display: "block",
  margin: "0 auto",
};
const sweetLoadingCss = {
    display: "flex",
    height: '90vh',
    justifyContent: "center",
    alignItems: "center"
}
function Spinner({loading}) {

  const color = "#6c27ff";

  return (
    loading ?
    <div className="sweet-loading" style={sweetLoadingCss}>

      <ClipLoader
        color={color}
        loading={loading}
        cssOverride={override}
        size={70}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
    : ""
    
  );
}

export default Spinner;

/* eslint-disable react/style-prop-object */
import { Header } from "../../components/Header";
import planItinerary from "../../assets/images/icon/plan-itinerary.png";
import forward from "../../assets/images/icon/forward.png";

import noImage from "../../assets/images/icon/no-image.png";
import events from "../../assets/images/background/events-img.svg";

// import { countries } from "../../utils/countries";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { BottomTabs } from "../../components/BottomTabs";

import { getAvailableCountries } from "./api";
import { getEvents, getFoodAndDrinks, getPlaces } from "../Activities/api";
import { useAuth } from "../../components/authentication";
import { getTrips } from "../MyTrip/Api";
import Modal from "../../components/Modal";
import { Locationpopup } from "../Tripplan/Locationpopup";
import { Country } from "../Tripplan/Country";
import Footer from "../../components/Footer";

export const Home = () => {
  const [selectedId, setSelectedId] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [getDataOfPlaces, setDataOfPlaces] = useState();
  const { authUser } = useAuth();
  const [getDataOfEvents, setDataOfEvents] = useState();
  const [getDataOfFoods, setDataOfFoods] = useState();
  const [availableCountries, setAvailableCountries] = useState();
  const [selectedCountries, setSelectedCountries] = useState();
  // const [listOfTrip, setListOfTrip] = useState();
  const [displayModal, setDisplayModal] = useState(false);
  const [displayCountryModal, setDisplayCountryModal] = useState(false);

  useEffect(() => {
    getAvailableCountries().then((res) => {
      setAvailableCountries(res);
      setSelectedCountries(res?.data[0]?.id);
    });
  }, []);
  useEffect(() => {
    setIsLoading(true);
    getPlaces(
      selectedCountries === undefined ? "" : `country_id=${selectedCountries}`
    ).then((resp) => {
      setDataOfPlaces(resp?.data);
      setIsLoading(false);
    });
    setIsLoading(true);

    getEvents(
      selectedCountries === undefined ? "" : `country_id=${selectedCountries}`
    ).then((resp) => {
      setDataOfEvents(resp?.data);
      setIsLoading(false);
    });
    getFoodAndDrinks(
      selectedCountries === undefined ? "" : `country_id=${selectedCountries}`
    ).then((resp) => {
      setDataOfFoods(resp?.data);
      setIsLoading(false);
    });
    if (authUser) {
      getTrips().then((resp) => {
        setIsLoading(false);
        // setListOfTrip(resp);
      });
    }
  }, [selectedCountries, authUser]);
  const navigate = useNavigate();
  const onSelectItem = (selectedItemId) => {
    if (selectedId !== selectedItemId) {
      setSelectedId(selectedItemId);
    }
    // else {
    //   setSelectedId(-1);
    // }
  };
  const [selectedMenu, setSelectedMenu] = React.useState(1);
  const onSelectMenu = (id) => {
    if (selectedMenu !== id) {
      setSelectedMenu(id);
    } else {
      setSelectedMenu(-1);
    }
  };
  const handleShowMore = () => {
    navigate("/activities/places", {
      state: {
        tab: "places",
        selectedCountry: selectedCountries,
      },
    });
  };
  const handleShowMoreFood = () => {
    navigate("/activities/food-and-drinks", {
      state: {
        tab: "food",
        selectedCountry: selectedCountries,
      },
    });
  };
  const handleShowMoreEvents = () => {
    navigate("/activities/events", {
      state: {
        tab: "events",
        selectedCountry: selectedCountries,
      },
    });
  };

  const [depCountryId, setDepCounrtyId] = useState();
  const [openProfileMenu, setOpenProfileMenu] = useState(false);

  return (
    // <MainLayout >
    <div className="home-started">
      <div
        className=" "
        onClick={(e) => {
          e?.stopPropagation();
        }}
      >
        <Header
          openProfileMenu={openProfileMenu}
          setOpenProfileMenu={setOpenProfileMenu}
        />

        <div
          className="home-page"
          onClick={(e) => {
            e.stopPropagation();
            setOpenProfileMenu(false);
            // document.body.style.overflow = "unset";
          }}
        >
          <div className="home-wrap">
            <div className="container">
              <div className="heading">
                <h2>Find curated experiences for your next trip</h2>
              </div>
              <p className="with-text">with Trundle</p>
            </div>
          </div>
          <div className="home-content">
            <div className="container">
              <div>
                <div className="active-trip-home">
                  {/* <div className="sub-blk">
              <div className="plan-trip flex items-center">
                <div className="text">
                  <p className="main-text">Plan your trip</p>
                  <p className="sub-text">Build your itinerary effortlessly</p>
                </div>
                <div className="icon">
                  <button className="transparent-button" onClick={() => {
                    setDisplayModal(true)
                  }}>
                    <img src={planItinerary} alt="plan-itinerary" />
                  </button>
                </div>
              </div>
              
            </div> */}
                  {/* <div>
              <button className="" >
                Next
              </button>
            </div> */}
                  <div className="sub-blk" style={{ width: "100%" }}>
                    <div className="plan-trip flex items-center">
                      <div className="text">
                        <p className="main-text">Plan your trip</p>
                        <p className="sub-text">
                          Build your itinerary effortlessly
                        </p>
                      </div>
                      <div className="icon">
                        <button
                          className="transparent-button"
                          onClick={() => {
                            setDisplayModal(true);
                            document.body.style.overflow = "hidden";
                          }}
                        >
                          <img src={planItinerary} alt="plan-itinerary" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Popular Places */}
                <div className="countries-tab">
                  {availableCountries?.data?.map((ele, id) => {
                    return (
                      <button
                        className={
                          id === selectedId
                            ? "active country-btn"
                            : "country-btn"
                        }
                        key={id}
                        onClick={() => {
                          onSelectItem(id);
                          setSelectedCountries(ele?.id);
                        }}
                      >
                        <img src={ele?.flag} alt={ele?.name} />
                        {ele?.name}
                      </button>
                    );
                  })}
                </div>
                {getDataOfPlaces?.results?.length === 0 ? (
                  ""
                ) : (
                  <section>
                    <div className="places-heading">
                      <p>Places</p>
                    </div>
                    <div className="places-wrap">
                      {getDataOfPlaces?.results?.slice(0, 5)?.map((element) => (
                        <>
                          <div className="places-card">
                            {/* <img src={element?.image} alt={element?.name} /> */}
                            {element?.locationimage_set.length === 0 ? (
                              // <div className="img-blk">
                              <img src={noImage} alt="places" />
                            ) : (
                              // </div>
                              // <div className="img-blk">
                              <img
                                src={element?.locationimage_set[0]}
                                alt="places"
                              />
                              // </div>
                            )}
                            <div className="places-section">
                              <p className="name">
                                {element?.name_of_location}
                              </p>
                              <p className="desc">{element?.type?.name}</p>
                              <div className="city-price flex justify-between">
                                <p>{element?.city?.name}</p>
                                <p>{element?.cost}</p>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                      <div className="show-more-card">
                        <button
                          className="wrap"
                          onClick={() => {
                            handleShowMore();
                          }}
                        >
                          <img src={forward} alt="forward" />
                          <p>Show More</p>
                        </button>
                      </div>
                    </div>
                  </section>
                )}
                {getDataOfEvents?.results?.length === 0 ? (
                  ""
                ) : (
                  <section>
                    <div className="places-heading">
                      <p>Events</p>
                    </div>
                    <div className="places-wrap">
                      {getDataOfEvents?.results?.slice(0, 5)?.map((element) => (
                        <>
                          <div
                            className="events-card"
                            style={{
                              backgroundImage: `url(${
                                element?.background || events
                              })`,
                            }}
                          >
                            <div className="events-section">
                              <p className="name">
                                {element?.name_of_location}
                              </p>
                              <p className="date">{element?.event_date}</p>
                              <div className="events flex justify-between">
                                <p>{element?.city?.name}</p>
                                <p>{element?.type}</p>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                      <div className="show-more-card events">
                        <button
                          className="wrap"
                          onClick={() => {
                            handleShowMoreEvents();
                          }}
                        >
                          <img src={forward} alt="forward" />
                          <p>Show More</p>
                        </button>
                      </div>
                    </div>
                  </section>
                )}
                {/* Food & Drinks */}
                {getDataOfFoods?.results?.length === 0 ? (
                  ""
                ) : (
                  <section>
                    <div className="places-heading">
                      <p>Food & Drinks</p>
                    </div>
                    <div className="places-wrap">
                      {getDataOfFoods?.results
                        ?.slice(0, 5)

                        ?.map((element) => (
                          <>
                            <div className="places-card">
                              {/* <img src={element?.image} alt={element?.name} /> */}
                              {element?.locationimage_set.length === 0 ? (
                                // <div className="img-blk">
                                <img src={noImage} alt="places" />
                              ) : (
                                // </div>
                                // <div className="img-blk">
                                <img
                                  src={element?.locationimage_set[0]}
                                  alt="places"
                                />
                                // </div>
                              )}
                              <div className="places-section">
                                <p className="name">
                                  {element?.name_of_location}
                                </p>
                                <div className="flex content">
                                  <p className="desc">{element?.type?.name}</p>

                                  {/* <img
                              src={element?.type === "veg" ? vegeterian : ""}
                              alt="type"
                              className="typeimg"
                            /> */}
                                </div>
                                <div className="city-price flex flex-col gap-2 justify-between">
                                  <p>{element?.cost_description}</p>
                                  <p>{element?.city?.name}</p>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      <div className="show-more-card">
                        <button
                          className="wrap"
                          onClick={() => {
                            handleShowMoreFood();
                          }}
                        >
                          <img src={forward} alt="forward" />
                          <p>Show More</p>
                        </button>
                      </div>
                    </div>
                  </section>
                )}
              </div>
            </div>
            <div className="bottom-menu">
              <BottomTabs
                onSelectMenu={onSelectMenu}
                selectedMenu={selectedMenu}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={displayModal}
        onClose={() => {
          setDisplayModal(false);
          document.body.style.overflow = "unset";
        }}
        title="Your Location"
      >
        <Locationpopup
          onSubmit={() => setDisplayModal(false)}
          setDisplayModal={setDisplayModal}
          setDepCounrtyId={setDepCounrtyId}
          setDisplayCountryModal={setDisplayCountryModal}
        />
      </Modal>
      <Modal
        isOpen={displayCountryModal}
        onClose={() => {
          setDisplayCountryModal(false);
          document.body.style.overflow = "unset";
        }}
        title="Country"
      >
        <Country
          // onhandleSubmit={() => handleSubmitPlan()}
          depCountryId={depCountryId}
          setDisplayCountryModal={setDisplayCountryModal}
          // setDepartureCounrtyId={setDepartureCounrtyId}
        />
      </Modal>
      <Footer />
    </div>
    // </MainLayout>
  );
};

import React, { useEffect, useState } from "react";
import image1 from "../../assets/images/icon/no-image2.svg";
import bookmark from "../../assets/images/icon/activity/bookmark.svg";
import calender from "../../assets/images/icon/activity/calendar.svg";
import locationImg from "../../assets/images/icon/activity/location.svg";
import { addItinerary, getEventsDetails } from "./api";
import Spinner from "../../components/Spinner";
import { useAuth } from "../../components/authentication";

import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

export const Viewmodal = ({ eventId, countryId, closeModal,  openPlanModalFn, closePlanModalFn, hideProceed }) => {
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true); // Add a loading state
  const {authUser} = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation()
  // const [getDetailsOfLocation, setDetailsOfLocation] = useState();

 const navigate = useNavigate()
  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const formData = new FormData();
        formData.append("id", eventId);
        const response = await getEventsDetails(formData);
        const eventData = response?.data;

        console.log("test3", eventData);

        setEvent(eventData);
        setLoading(false);
      } catch (error) {
        console.log("Error fetching event details:", error);
        setLoading(false);
      }
    };

    fetchEventDetails();
  }, [eventId]);

  if (loading) {
    return <Spinner loading={loading} />;
  }

  let products = JSON.parse(localStorage.getItem("cart")) || [];

  // Function to handle adding a product to the cart
  function addToItineraryFn(product) {
    if(authUser){
      setIsLoading(true);
      let data = new FormData();
      data.append("location_id", product?.id);
  
      data.append("trip_id", location?.state?.trip_id);
      addItinerary(data)
        .then((resp) => {
          closePlanModalFn();
          closeModal();
          // navigate("/tripplan2");
          navigate("/tripplan2", {
            state: {
              selectedCountry: countryId,
              trip_id: location?.state?.trip_id
            },
          });
  
          setIsLoading(false);
          toast.success(resp?.message);
        })
        .catch((err) => {
          closePlanModalFn();
          closeModal();
          setIsLoading(false);
        });
    }else{
      function isProductInCart(productId) {
        return products?.some(item => item?.location?.id === productId);
      }
      if (isProductInCart(product?.id)) {
        // console.log("Product already in cart");
        toast.error("Itinerary already added")
    closeModal();
  
        return;
      }
      
    // Create a "location" object for the product
    let location = {
      ...product
      // other product details
    };
  
    // Add the "location" object to the cart
    products.push({ location });
  
    // Save the updated cart to local storage
    localStorage.setItem('cart', JSON.stringify(products));
    closeModal();
  
  navigate("/tripplan2");
    }
  }
  if (!event) {
    return <div>Event not found</div>;
  }

  const {
    name_of_location,
    address,
    city,
    event_date,
    description,
    open_timings,
    buy_ticket,
    type_of_event,
    locationimage_set,
  } = event;

  return (
    <>
    <div className="desktop1">
      <img
        className="viewmodal-image"
        src={locationimage_set.length ? locationimage_set[0] : image1}
        alt="Location"
      />

      <div className="blur-div">
        <img className="bookmark-image" src={bookmark} alt="bookmark" />

        <p className="popup-place-text">{name_of_location}</p>
        <p className="landmark-text">{type_of_event}</p>

        <div className="location-calender">
          <div className="location-div">
            <div className="location-calender-subdiv">
              <img src={locationImg} alt="location" />
            </div>
            <div>
              <p className="location-title-text">{city?.name}</p>
              <p className="location-address-text">{address}</p>
            </div>
          </div>
          <div className="calender-div ">
            <div className="location-calender-subdiv">
              <img src={calender} alt="calendar" />
            </div>
            <div>
              <p className="location-title-text">Date & time</p>
              <p className="location-address-text">
                {event_date}
                {/* & {open_timings} */}
              </p>
            </div>
          </div>
        </div>
        <p className="description-text">{description}</p>
        {/* <a href={buy_ticket} target="_blank" rel="noopener noreferrer">
          Buy Ticket
        </a> */}
        {!hideProceed ?
        <button  className="primary-button"    onClick={() => {
          console.log("hiiiii")

                  if (authUser && !location?.state?.trip_id) {
                    openPlanModalFn();

                    // closeModal()
                  } else {
                    document.body.style.overflow = "unset";
                    addToItineraryFn(event);
                    // closeModal()
                    // navigate("/login");
                

                  }
                }}>
          Proceed
        </button>
        : ""
}
     
      </div>
    </div>


    </>
  );
};

import { MainLayout } from "../../components/MainLayout";

import { LoginByEmail } from "./LoginByEmail";

export const Login = () => {
  // const tabItems = [
  //   {
  //     id: 1,
  //     title: "Phone No",
  //     content: <LoginByPhone />,
  //   },
  //   {
  //     id: 2,
  //     title: "Email Id",
  //     content: <LoginByEmail />,
  //   },
  // ];

  return (
    <MainLayout className={"layout-card"}>
         <div className="container">

      <div className="signup-layout">
        <h3 className="heading-layout">Welcome</h3>
        <p className="sub-heading">
        Please enter your email address to proceed further
        </p>

        <div className="tab-wrap">
          {/* <TabsComponent tabItems={tabItems} /> */}
          <LoginByEmail />
        </div>
      </div>
      </div>
    </MainLayout>
  );
};

import { axios } from "../../../utils/axios"

export const getTip = () => {
    return axios.get(`/api-v1/trip/get-tips`)
}
export const getLearnLanguage = () => {
    return axios.get(`/api-v1/trip/get-learn-languages`)
}
export const getEmergency = () => {
    return axios.get(`/api-v1/trip/get-emergency`)
}
export const getTrips = () => {
    return axios.get(`/api-v1/trip/user-trips`)
}
export const getTripDetails = (data) => {
    return axios.post(`/api-v1/trip/get-trip`, data)
}
export const getTripItinerary = (data) => {
    return axios.post(`/api-v1/itinerary/trip-itineraries`, data)
}
export const updateTripDates = (data) => {
    return axios.post(`/api-v1/trip/update-trip`, data)
}
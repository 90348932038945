import { useNavigate } from "react-router-dom";
import { MainLayout } from "../../components/MainLayout";

// import { TabsComponent } from "../../components/Tab/TabsComponent";

import { SignUpEmail } from "./SignUpEmail";
import { useAuth } from "../../components/authentication";
import { useEffect } from "react";
// import { SignUpPhone } from "./SignUpPhone";
export const SignUp = () => {
  const navigate = useNavigate();
  const { authUser } = useAuth();

  useEffect(() => {
    if (authUser) {
      return navigate("/");
    }
  }, [authUser]);
  // const tabItems = [
  //   {
  //     id: 1,
  //     title: "Phone No",
  //     content: <SignUpPhone />,
  //   },
  //   {
  //     id: 2,
  //     title: "Email Id",
  //     content: <SignUpEmail />,
  //   },
  // ];

  return (
    <MainLayout className={"layout-card"}>
         <div className="container">
      <div className="signup-layout">
        <h3 className="heading-layout">Welcome</h3>
        <p className="sub-heading">
          Please enter your email address to proceed further
        </p>

        <div className="tab-wrap">
          {/* <TabsComponent tabItems={tabItems} /> */}
          <SignUpEmail />
        </div>
        </div>
      </div>
    </MainLayout>
  );
};

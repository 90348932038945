import alert from "../../assets/images/icon/alert.png";

import { useLocation, useNavigate } from "react-router-dom";
import { MainLayout } from "../../components/MainLayout";
import React, { useRef, useState } from "react";
import { verifyOTP } from "./api";
import { resendOtp } from "../Login/api";
import { toast } from "react-hot-toast";
import { useAuth } from "../../components/authentication";
import { axios } from "../../utils/axios";

const useCountDown = (start) => {
  const [counter, setCounter] = React.useState(start);
  React.useEffect(() => {
    if (counter === 0) {
      return;
    }
    const timeoutID = setTimeout(() => {
      setCounter(counter - 1);
    }, 1000);
    return () => clearTimeout(timeoutID);
  }, [counter]);
  return counter;
};

export const OTPScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { getAuthUser, authUser } = useAuth();
  const [isVisible, setIsVisible] = useState(true);
  const [count, setCount] = useState(1);
  function Countdown({ seconds }) {
    const timeLeft = useCountDown(seconds);
    return (
      <>
        <p className="countdown">
          {timeLeft > 60
            ? Math.ceil(timeLeft / 60) - 1 + ":" + (timeLeft % 60)
            : timeLeft}
          s
        </p>
        {timeLeft === 0 ? setIsVisible(false) : ""}
      </>
    );
  }
  const data = location?.state?.data;

  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const inputRef5 = useRef(null);
  const inputRef6 = useRef(null);

  const [error, setError] = useState(false);
  const handleChange = (event, index) => {
    const value = event?.target?.value;

    // copy the OTP state array
    const newOtp = [...otp];

    // update the OTP value at the given index
    newOtp[index] = value;

    // update the OTP state
    setOtp(newOtp);

    // move focus to the next input box, if available
    if (value && index < 5) {
      switch (index) {
        case 0:
          inputRef2.current.focus();
          break;
        case 1:
          inputRef3.current.focus();
          break;
        case 2:
          inputRef4.current.focus();
          break;
        case 3:
          inputRef5.current.focus();
          break;
        case 4:
          inputRef6.current.focus();
          break;
        default:
          break;
      }
    }
  };
  const clearOTPValues = () => {
    setOtp(["", "", "", "", "", ""]);
  };
  let itinerary = JSON.parse(localStorage.getItem("itinerary")) || [];
  let countryInfo = JSON.parse(localStorage.getItem("planTrip")) || {};

  /* function for submitting the OTP */
  const handleSubmit = async (event) => {
    let idList = itinerary.map((item) => item.location.id).join(", ");
    console.log(idList);
    event.preventDefault(); // prevent form submission
    const enteredOTP = otp.join(""); // join the OTP array into a string

    var FormData = require("form-data");
    var data = new FormData();
    data.append("email", location?.state?.data);
    data.append("otp", enteredOTP);
    data.append("country_id", countryInfo?.country_id);
    data.append("departure_country_id", countryInfo?.departure_country_id);
    data.append("location_id", idList);

    verifyOTP(data)
      .then(async (res) => {
        localStorage.removeItem("cart");
        localStorage.removeItem("itinerary");
        localStorage.removeItem("planTrip");
        if(!res?.extra_error?.message) {
           console.log("hello")
        } else toast.error(res?.extra_error?.message)
        if (res?.status === true) {
          setError(false);
          localStorage.setItem(
            "trundle_token",
            JSON.stringify(res?.access_token)
          );
        
          await getAuthUser();
          axios
            .get("api-v1/account/get-user")
            .then(({ data }) => {
              if (data) {
                if (!data?.first_name) {
                  navigate("/createprofile", {
                    state: {
                      data: location?.state?.data,
                    },
                  });
                } else {
                  navigate("/", {
                    state: {
                      data: location?.state?.data,
                    },
                  });
                }
              }
            })
            .catch(function (error) {
              console.log(error);
            });
          // OTP matched, do something here
          // setError(false);

          // localStorage.setItem(
          //   "trundle_token",
          //   JSON.stringify(res?.access_token)
          // );
        }
      })
      .catch((error) => {
        // OTP did not match, show error message
        setCount(count + 1);
        // if (count === 3) {
        setIsVisible(false);
        setCount(1);
        clearOTPValues();
        // }
        setError(true);
      });
  };
  const handleResendOTP = () => {
    setError(false);
    var formdata = new FormData();
    formdata.append("email", location?.state?.data);
    resendOtp(formdata)
      .then((res) => {
        if (res?.status === true) {
          setIsVisible(true);
          toast.success(res?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      <MainLayout className={"layout-card"}>
        <div className="">
          <div className="signup-layout otp-layout">
            <h3 className="heading-layout">OTP Verification</h3>
            <p className="sub-heading">OTP sent to {data}</p>
            <div>
              <form
                className="flex otp-form justify-between"
                onSubmit={handleSubmit}
              >
                <div>
                  <div className="flex">
                    <div className="input-wrap">
                      <input
                        type="number"
                        maxLength="1"
                        className="otp-box"
                        value={otp[0]}
                        onChange={(e) => {
                          handleChange(e, 0);
                        }}
                        ref={inputRef1}
                      />
                    </div>
                    <div className="input-wrap">
                      <input
                        type="number"
                        maxLength="1"
                        className="otp-box"
                        value={otp[1]}
                        onChange={(e) => {
                          handleChange(e, 1);
                        }}
                        ref={inputRef2}
                        onKeyDown={(e) => {
                          if (e.keyCode === 8) {
                            inputRef1?.current?.focus();
                          }
                        }}
                      />
                    </div>
                    <div className="input-wrap">
                      <input
                        type="text"
                        className="otp-box"
                        maxLength="1"
                        value={otp[2]}
                        onChange={(e) => {
                          handleChange(e, 2);
                        }}
                        ref={inputRef3}
                        onKeyDown={(e) => {
                          if (e.keyCode === 8) {
                            inputRef2?.current?.focus();
                          }
                        }}
                      />
                    </div>
                    <div className="input-wrap">
                      <input
                        type="text"
                        maxLength="1"
                        className="otp-box"
                        value={otp[3]}
                        onChange={(e) => {
                          handleChange(e, 3);
                        }}
                        ref={inputRef4}
                        onKeyDown={(e) => {
                          // if (e?.target?.value === "") {
                          if (e.keyCode === 8) {
                            inputRef3?.current?.focus();
                          }
                          // else if (e.target.value.toString().length === 1) {
                          //   if (e.keyCode === 8 || e.keyCode === 46) {
                          //   } else e.preventDefault();
                          // }
                          // }
                        }}
                      />
                    </div>
                    <div className="input-wrap">
                      <input
                        type="number"
                        maxLength="1"
                        className="otp-box"
                        value={otp[4]}
                        onChange={(e) => {
                          handleChange(e, 4);
                        }}
                        ref={inputRef5}
                        onKeyDown={(e) => {
                          if (e.keyCode === 8) {
                            inputRef4?.current?.focus();
                          }
                        }}
                      />
                    </div>
                    <div className="input-wrap">
                      <input
                        type="number"
                        maxLength="1"
                        className="otp-box"
                        value={otp[5]}
                        onChange={(e) => {
                          handleChange(e, 5);
                        }}
                        ref={inputRef6}
                        onKeyDown={(e) => {
                          if (e?.target?.value === "") {
                            if (e.keyCode === 8) {
                              inputRef5?.current?.focus();
                            } else if (e.target.value.toString().length === 1) {
                              if (e.keyCode === 8 || e.keyCode === 46) {
                              } else e.preventDefault();
                            }
                          }
                        }}
                      />
                    </div>
                  </div>
                  {/* code for showing error , if otp didn't matched*/}
                  {error && <p className="text-danger">OTP did not match</p>}
                  {/* code for resend otp which contains countdown timer */}
                  {isVisible === true ? (
                    <div className="countdown-wrap">
                      {<Countdown seconds={100} />}
                    </div>
                  ) : (
                    <div className="countdown-wrap">
                      <button
                        type="button"
                        className="countdown"
                        onClick={() => {
                          handleResendOTP();
                        }}
                      >
                        Resend OTP
                      </button>
                    </div>
                  )}
                </div>

                <div className="alert-wrap">
                  <div className="alert-container">
                    <div className="alert">
                      <img src={alert} alt="alert-icon" />
                      <p>
                        Please enter the latest code we sent to your email
                        address.
                      </p>
                    </div>
                    {/* we can access value of input box by using ref keyword -> ref.current.value */}
                    <div className="button-wrap">
                      <button
                        className="primary-button button-signup"
                        type="submit"
                        disabled={
                          !inputRef1?.current?.value ||
                          !inputRef2?.current?.value ||
                          !inputRef3?.current?.value ||
                          !inputRef4?.current?.value ||
                          !inputRef5?.current?.value ||
                          !inputRef6?.current?.value
                        }
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

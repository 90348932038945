/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo/logo.png";
import React, { useState } from "react";
import home from "../../assets/images/icon/home.svg";
import back from "../../assets/images/icon/arrow_back.png";
import rotateicon from "../../assets/images/icon/rotate.png";

import homeActive from "../../assets/images/icon/home-active.png";
import trip from "../../assets/images/icon/trip.png";
import logout from "../../assets/images/icon/logout.png";
import help from "../../assets/images/icon/help.png";
import support from "../../assets/images/icon/support.png";
import avatar1 from "../../assets/images/icon/Avatar/avatar1.png";

import arrow from "../../assets/images/icon/arrow.svg";
import avataar from "../../assets/images/icon/avataar.png";
import camera from "../../assets/images/icon/camera.png";
import deleteIcon from "../../assets/images/icon/delete.png";
import gallery from "../../assets/images/icon/gallery.png";

import tripActive from "../../assets/images/icon/trip-active.svg";
import bookmark from "../../assets/images/icon/bookmark.svg";
import capture from "../../assets/images/icon/capture.png";

import bookmarkAdded from "../../assets/images/icon/bookmark_added.svg";
import accountActive from "../../assets/images/icon/accountActive.svg";
import account from "../../assets/images/icon/account.svg";
import Webcam from "react-webcam";
import { EditProfile } from "./EditProfile";
import Modal from "../Modal";
import { useEffect } from "react";
import { useAuth } from "../authentication";
import { AvatarModal } from "./AvatarModal";

export const Header = ({ openProfileMenu, setOpenProfileMenu }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { authUser, userSignOut } = useAuth();
  const [rotation, setRotation] = useState(0);
  const [isCameraVisible, setIsCameraVisible] = React.useState(false);
  const [isScreenshotVisible, setIsScreenshotVisible] = React.useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [displayHelpModal, setDisplayHelpModal] = useState(false);

  const [displayProfileModal, setDisplayProfileModal] = useState(false);
  const [screenshot, setScreenshot] = useState("");
  const [isWebcamLoading, setIsWebcamLoading] = useState(true);
  const [isHelp, setIsHelp] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [files, setFile] = useState("");
  const [avatarModal, setOpenAvatarModal] = useState(false);
  const handleLogout = () => {
    userSignOut();
  };
  const [selectedMenu, setSelectedMenu] = React.useState(1);
  const onSelectMenu = (id) => {
    if (selectedMenu !== id) {
      setSelectedMenu(id);
    } else {
      setSelectedMenu(-1);
    }
  };
  const handleLogin = () => {
    navigate("/login");
  };
  const handleSignup = () => {
    navigate("/signup");
  };

  const handleRotate = () => {
    let newRotation = rotation + 90;
    if (newRotation >= 360) {
      newRotation = -360;
    }
    setRotation(newRotation);
  };
  useEffect(() => {
    if (openProfileMenu === false && location.pathname === "/") {
      setSelectedMenu(1);
    }
  }, [openProfileMenu]);
  const openAvatarModal = () => {
    setOpenAvatarModal(true);
  };
  const closeAvatarModal = () => {
    setOpenAvatarModal(false);
  };
  const HelpContent = () => {
    return (
      <>
        <div className="content-wrap cancellation-policy">
          <p className="content-title">Cancellation Policy</p>
          <p className="content">
            Most of our activities come with a 24-hour free cancellation policy,
            meaning you can cancel your booking up until 24 hours before the
            activity starts to receive a full refund.
          </p>
          <p className="content">
            You'll find more information regarding your booking's cancellation
            policy on your voucher. Your booking will either be fully
            refundable, partially refundable or non-refundable depending on the
            activity provider's policy. All cancelable bookings will display a
            deadline for cancellation on the voucher.
          </p>
        </div>
        <div className="footer cancellation-policy-footer">
          <div className="flex items-start" style={{ gap: 16 }}>
            <img src={support} alt="support" />
            <div className="complaints-wrap">
              <p>Feedback or complaints about the app</p>
              <a href="mailto:care@trundle.world">care@trundle.world</a>
            </div>
          </div>
        </div>
      </>
    );
  };
  const ProfilePhoto = () => {
    return (
      <>
        <div className="flex justify-between items-start">
          <button
            className="transparent-button flex items-center gap-2 flex-col"
            onClick={() => {
              setIsCameraVisible(true);
            }}
          >
            <img src={camera} alt="camera" />
            <p>Camera</p>
          </button>
          <button className="transparent-button flex items-center gap-2 flex-col">
            <label
              for="files"
              class="btn"
              style={{ cursor: "pointer" }}
              className="flex items-center gap-2 flex-col"
            >
              <img src={gallery} alt="gallery" />
              <p>Gallery</p>
            </label>
            <input
              id="files"
              style={{ visibility: "hidden", display: "none" }}
              type="file"
              accept="image/png, image/jpeg"
              onChange={(e) => {
                setFile(e.target.files[0]);
                setDisplayProfileModal(false);
              }}
            />
          </button>
          <button
            className="transparent-button flex items-center gap-2 flex-col"
            onClick={() => {
              openAvatarModal();
            }}
          >
            <img src={avataar} alt="avataar" />
            <p>Avatar</p>
          </button>
          <button className="transparent-button flex items-center gap-2 flex-col">
            <img src={deleteIcon} alt="delete" />
            <p>Delete</p>
          </button>
        </div>
      </>
    );
  };
  const openProfileModal = () => {
    setDisplayProfileModal(true);
  };
  const closeProfileModal = () => {
    setDisplayProfileModal(false);
  };
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
  };
  const [selectedImg, setSelectedImg] = useState(avatar1);
  const [isAvatarEdit, setIsAvatarEdit] = useState(false);

  return (
    <div
      className={
        location?.pathname === "/createprofile" ||
        location?.pathname === "/signup" ||
        location?.pathname === "/login" ||
        location?.pathname === "/otp"
          ? ""
          : "header-starts"
      }
    >
      <div className="container">
        <div className="header">
          <div className="flex items-center justify-between">
            <button
              className="flex items-center logo-wrap transparent-button"
              onClick={() => {
                navigate("/");
              }}
            >
              <img src={logo} alt="logo" className="logo" />
              <p class="logoText">trundle</p>
            </button>
            <ul className="menu flex items-center">
              {location?.pathname === "/login" ? (
                <>
                  <li>Already have an account?</li>

                  <li>
                    <button className="primary-button" onClick={handleSignup}>
                      Sign Up
                    </button>
                  </li>
                </>
              ) : location?.pathname === "/createprofile" ||
                location?.pathname === "/otp" ? (
                <>
                  <li>Already have an account?</li>

                  <li>
                    <button className="primary-button" onClick={handleLogin}>
                      Login
                    </button>
                  </li>
                </>
              ) : (
                <>
                  {authUser ? (
                    <ul className="flex justify-between">
                      <li>
                        <button
                          onClick={() => {
                            onSelectMenu(1);
                            navigate("/");
                          }}
                          className={
                            selectedMenu === 1 && location?.pathname === "/"
                              ? "activeMenu menu-list"
                              : "menu-list"
                          }
                        >
                          <img
                            src={
                              selectedMenu === 1 && location?.pathname === "/"
                                ? homeActive
                                : home
                            }
                            alt="home"
                          />
                          Home
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => {
                            onSelectMenu(2);
                            navigate("/my-trip");
                          }}
                          className={
                            location?.pathname === "/my-trip" ||
                            location?.pathname === "/trip-details"
                              ? "activeMenu menu-list"
                              : "menu-list"
                          }
                        >
                          <img
                            src={
                              location?.pathname === "/my-trip" ||
                              location?.pathname === "/trip-details"
                                ? tripActive
                                : trip
                            }
                            alt="trip"
                          />
                          My Trips
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => {
                            onSelectMenu(3);
                          }}
                          className={
                            selectedMenu === 3
                              ? "activeMenu menu-list"
                              : "menu-list"
                          }
                        >
                          <img
                            src={selectedMenu === 3 ? bookmarkAdded : bookmark}
                            alt="bookmark"
                          />
                          Bookmarks
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => {
                            onSelectMenu(4);
                            setOpenProfileMenu(true);
                          }}
                          className={
                            selectedMenu === 4
                              ? "activeMenu menu-list"
                              : "menu-list"
                          }
                        >
                          <img
                            src={selectedMenu === 4 ? accountActive : account}
                            alt="profile"
                          />
                          Profile
                        </button>
                      </li>
                    </ul>
                  ) : (
                    <>
                      <li>
                        <button
                          className="secondary-button"
                          onClick={handleLogin}
                        >
                          Sign in
                        </button>
                      </li>
                      <li>
                        <button
                          className="primary-button"
                          onClick={handleSignup}
                        >
                          Sign up
                        </button>
                      </li>
                    </>
                  )}
                </>
              )}
            </ul>
          </div>
          {openProfileMenu === true ? (
            <div>
              <div className="profile-menu">
                <div className="sub-content-1">
                  <div className="content-wrap">
                    <img
                      src={!authUser?.photo ? account : authUser?.photo}
                      alt="userProfile"
                    />
                    <p className="username">
                      {authUser?.first_name} {authUser?.last_name}
                    </p>
                    <p className="email">{authUser?.email}</p>
                    <div className="button-wrap">
                      <button
                        className="secondary-button"
                        onClick={() => {
                          setDisplayModal(!displayModal);
                          setIsEdit(!isEdit);
                          document.body.style.overflow = "hidden";
                        }}
                      >
                        Edit Profile
                      </button>
                    </div>
                  </div>
                </div>

                <div className="sub-content-2">
                  <div className="content-wrap">
                    <ul>
                      <li>
                        <button
                          className="transparent-button w-100 flex items-center justify-between"
                          onClick={() => {
                            setDisplayHelpModal(!displayHelpModal);
                            setIsHelp(!isHelp);
                            document.body.style.overflow = "hidden";
                          }}
                        >
                          <div className="flex items-center gap-2">
                            <img src={help} alt="help" />
                            <p>Help</p>
                          </div>
                          <div className="arrow">
                            <img src={arrow} alt="arrow" />
                          </div>
                        </button>
                      </li>
                      <li>
                        <button
                          className="transparent-button w-100 flex items-center justify-between"
                          onClick={() => {
                            handleLogout();
                            setOpenProfileMenu(false);
                          }}
                        >
                          <div className="flex items-center gap-2">
                            <img src={logout} alt="help" />
                            <p>Log out</p>
                          </div>
                          <div className="arrow">
                            <img src={arrow} alt="arrow" />
                          </div>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {isCameraVisible ? (
            <>
              <div
                className="webcam-wrap"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsCameraVisible(false);
                }}
              >
                <div
                  className="webcam-blk"
                  onClick={(e) => {
                    e?.stopPropagation();
                  }}
                >
                  {/* {isWebcamLoading ? (
                <div className="grey-bg"></div>
              ) : ( */}
                  <Webcam
                    audio={false}
                    height={"100%"}
                    onUserMedia={() => {
                      setIsWebcamLoading(false);
                    }}
                    screenshotFormat="image/jpeg"
                    width={"100%"}
                    className={isWebcamLoading ? "grey-bg" : "webcam"}
                    videoConstraints={videoConstraints}
                  >
                    {({ getScreenshot }) => (
                      <button
                        className="transparent-button webcam-capture-btn"
                        onClick={() => {
                          const imageSrc = getScreenshot();
                          setScreenshot(imageSrc);
                          setIsCameraVisible(false);
                          setIsScreenshotVisible(true);
                        }}
                      >
                        <img src={capture} alt="capture" />
                      </button>
                    )}
                  </Webcam>
                  {/* )} */}
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {isScreenshotVisible && (
            <div
              className="webcam-wrap"
              onClick={(e) => {
                e.stopPropagation();
                setIsScreenshotVisible(false);
                setScreenshot("");
              }}
            >
              <div
                className="webcam-blk screenshot"
                onClick={(e) => {
                  e?.stopPropagation();
                }}
              >
                <div className="flex items-center crop-rotate">
                  <img src={back} alt="back" />
                  <div className="w-100">
                    <p className="text-center crop-rotate-text">
                      Crop & Rotate
                    </p>
                  </div>
                </div>

                <div className="flex-col flex justify-center items-center">
                  <div className="captured">
                    <div className="captured-photo-blk">
                      <div className="first_b"></div>
                      <div className="first_b2"></div>
                      <div className="bottom_b1"></div>
                      <div className="bottom_b2"></div>
                      <div className="topright_b1"></div>
                      <div className="topright_b2"></div>
                      <div className="bottomright_b1"></div>
                      <div className="bottomright_b2"></div>
                      <div className="centertop"></div>
                      <div className="centerbottom"></div>

                      <img
                        src={screenshot}
                        alt="photo"
                        style={{ transform: `rotate(${rotation}deg)` }}
                        className="captured-photo"
                        id="change"
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div className="flex-col flex justify-center items-center">
                  <button
                    className="transparent-button flex items-center justify-center"
                    style={{ color: "white", gap: 5 }}
                    onClick={() => {
                      handleRotate();
                    }}
                  >
                    <img src={rotateicon} alt="rotate" />
                    Rotate
                  </button>
                  <br />
                  <button
                    className="primary-button"
                    style={{ color: "white" }}
                    onClick={() => {
                      setIsScreenshotVisible(false);
                    }}
                  >
                    Save as Profile Picture
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={displayHelpModal}
        onClose={setDisplayHelpModal}
        title={"Help"}
      >
        <div className="Modal-custom">
          <HelpContent />
        </div>
      </Modal>
      <Modal
        isOpen={displayModal}
        onClose={() => {
          setDisplayModal(false);
          setIsAvatarEdit(false);
          setFile("")
        }}
        title={"Edit Profile"}
        className="edit-modal"
      >
        <EditProfile
          openProfileModal={openProfileModal}
          setDisplayModal={setDisplayModal}
          screenshot={screenshot}
          files={files}
          setFile={setFile}
          isAvatarEdit={isAvatarEdit}
          selectedImg={selectedImg}
        />
      </Modal>

      <div className="profile-modal">
        <Modal
          isOpen={displayProfileModal}
          onClose={closeProfileModal}
          title={"Profile Photo"}
        >
          <ProfilePhoto />
        </Modal>
      </div>

      {/* <div className="profile-modal"> */}
      <Modal
        isOpen={avatarModal}
        onClose={closeAvatarModal}
        title={"Create Avatar"}
        className={"avatar-modal"}
      >
        <AvatarModal
          files={files}
          setSelectedImg={setSelectedImg}
          selectedImg={selectedImg}
          setFile={setFile}
          closeProfileModal={closeProfileModal}
          closeAvatarModal={closeAvatarModal}
          setIsAvatarEdit={setIsAvatarEdit}
        />
      </Modal>
      {/* </div> */}
    </div>
  );
};

import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { axios } from "../../../utils/axios";
import storage from "../../../utils/storage";


export const useProvideAuth = () => {
  const [authUser, setAuthUser] = useState(null);
  const token = storage.getToken();

  const [error, setError] = useState("");
  const [isLoadingUser, setLoadingUser] = useState(true);
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState("");

  const [isLoading, setLoading] = useState(false);

  const fetchStart = () => {
    setLoading(true);
    setError("");
  };

  const fetchSuccess = () => {
    setLoading(false);
    setError("");
  };

  const fetchError = (error) => {
    setLoading(false);
    setError(error);
  };

  const userSignOut = () => {
    fetchStart();
    fetchError("");

    fetchSuccess();
    // axios.defaults.headers.common['authorization'] = '';
    storage.clearToken();
    setAuthUser(false);
  };
  const resendOTP = (user, datas) => {
    fetchError("");
    fetchStart();
    axios
      .post(`/api-v1/account/resend-otp`, user)
      .then((data ) => {
        if (data?.status === true) {
          fetchSuccess();
          navigate("/otp", {
            state: {
              data: datas.email,
              // redirection: "signin",
            },
          });
        } else {
          fetchError(error?.response?.data?.message);
        }
      })
      .catch(function (error) {
        fetchError(error?.response?.data?.message);
      });
  };

  const getAuthUser = () => {
    fetchStart();
    fetchError("");
    axios
      .get("api-v1/account/get-user")
      .then(({ data }) => {
        if (data) {
          setAuthUser(data);
        }
        setLoadingUser(false);
      })
      .catch(function (error) {
        setLoadingUser(false);
        fetchError(error?.response?.data?.message);
      });
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common["authorization"] = token;
      axios
        .get("api-v1/account/get-user")
        .then(({ data }) => {
          if (data) {
            setAuthUser(data);
          }
          setLoadingUser(false);
        })
        .catch(function () {
          storage.clearToken();
          setLoadingUser(false);
        });
    } else {
      setLoadingUser(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Return the user object and auth methods
  return {
    isLoadingUser,
    isLoading,
    authUser,
    error,
    setError,
    setAuthUser,
    getAuthUser,
    userSignOut,
    resendOTP,
    successMessage,
    setSuccessMessage,
  };
};

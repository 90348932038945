import { useEffect, useState } from "react";
import React from "react";
import Modal from "../../components/Modal";
import { Viewmodal } from "./Viewmodal";
import events from "../../assets/images/background/events-img.svg";

import { getEvents, getFilters } from "./api";
import places from "../../assets/images/icon/eventsEmpty.png";

import PaginationCustom from "../../components/PaginationCustom.js";
import Spinner from "../../components/Spinner";
import { TripPlanModal } from "./TripPlanModal";

export const Events = ({ setCountryId, countryId, interest, getDataOfEvents, setDataOfEvents }) => {
  const [displayModal, setDisplayModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedMenu, setSelectedMenu] = useState(-1);
  const [openPlanModal, setOpenPlanModal] = useState(false);
  const openPlanModalFn = () => {
    document.body.style.overflow = "hidden";
    setOpenPlanModal(true);
    console.log(openPlanModal, "open")
    // closeModal()
  };

  const closePlanModalFn = () => {
    setOpenPlanModal(false);
    document.body.style.overflow = "unset";
  };
  useEffect(() => {
    setIsLoading(true);
    const countryquery = !countryId ? "" : `country_id=${countryId}`;
    const typequery = !interest ? "" : `type=${interest}&`;
    const currentPageQuery = `page=${currentPage}`;
    getEvents(
      (countryquery ? `${countryquery}&` : "") + typequery + currentPageQuery
    ).then((resp) => {
      setDataOfEvents(resp?.data);
      setIsLoading(false);
    });
  }, [interest, countryId, selectedMenu, currentPage]);
  const [filters, setFilters] = useState();

  const openModal = async (eventId) => {
    console.log(eventId, "event")
    try {
      setDisplayModal(true);
      setSelectedId(eventId);
    } catch (error) {
      console.log("Error opening modal:", error);
    }
  };
  const onSelectMenu = (id, country) => {
    console.log(country,"country")
    setIsLoading(true);

    if (selectedMenu !== id) {
      setSelectedMenu(id);
    } else {
      setSelectedMenu(-1);
    }

    getEvents(`country_id=${country === "all" ? "" : country}`).then((resp) => {
      setDataOfEvents(resp?.data);
      setIsLoading(false);
      setCountryId(country === "all" ? "" : country);
    });
  };
  useEffect(() => {
    const query = `country_id=${!countryId? "" : countryId}`
    getFilters(query).then((resp) => {
      setFilters(resp?.data);
    });
  }, []);
  console.log(isLoading, "isloading");

  return !isLoading ? (
    <div className="r-events-main-div">
      <div className="cities-filters">
        <div className="filters-btn-wrap flex gap-2 items-center">
          <button
            className={selectedMenu === -1 ? "active filter-btn" : "filter-btn"}
            onClick={() => {
              onSelectMenu(-1 , "all");
            }}
          >
            All
          </button>

          {filters?.Cities?.map((ele, i) => {
            return (
              <button
                className={
                  selectedMenu === i ? "active filter-btn" : "filter-btn"
                }
                onClick={() => {
                  onSelectMenu(i, ele?.Country);
                }}
              >
                {ele?.name}
              </button>
            );
          })}
        </div>
      </div>
      <br />
      <section>
        <Modal isOpen={displayModal} onClose={() => setDisplayModal(false)}>
          <Viewmodal
            eventId={selectedId}
            hideProceed={false}

            // setCountryId={setCountryId}
            countryId={countryId}
            closeModal={() => setDisplayModal(false)}
            openPlanModalFn={openPlanModalFn}
            closePlanModalFn={closePlanModalFn}
          />
        </Modal>
        {getDataOfEvents?.results?.length === 0 ? (
          <>
            <div className="flex items-center flex-col justify-center h-100 empty-state">
              <img src={places} alt="empty" />
              <p className="empty-state-text">No events found</p>
            </div>
          </>
        ) : (
          <>
            <div className="r-events-grid">
              {getDataOfEvents?.results?.map((element) => (
                <button
                  key={element.id}
                  className="activities-places-card transparent-button"
                  onClick={() => openModal(element.id)}
                >
                  <div
                    className="r-events-card"
                    style={{
                      backgroundImage: `url(${
                        element?.locationimage_set[0]?.image || events
                      })`,
                    }}
                  >
                    <div className="r-events-section">
                      <div className="flex">
                        <img
                          className="r-event-img"
                          src={element?.locationtiming_set}
                          alt=""
                        />
                        <div className="r-innerevent-tile">
                          <p className="r-event-name">
                            {element?.name_of_location}
                          </p>
                          <p className="r-event-date">{element?.event_date}</p>
                          <div className="r-event-place flex">
                            <p className="r-event-place-text">
                              {element?.city?.name}
                            </p>
                            <span className="r-event-place-text r-gap-dot">
                              -
                            </span>
                            <p className="r-event-place-text">
                              {element?.type_of_event}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </button>
              ))}
            </div>
          
              <div className="pagination-wrap">
              {getDataOfEvents?.count > 12 ? (
                <PaginationCustom
                  data={getDataOfEvents}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  buttonConst={5}
                  contentPerPage={12}
                  siblingCount={1}
                  setDataOfPlaces={setDataOfEvents}
                  section="food-n-drinks"
                />
                ) : (
                  ""
                )}
              </div>
        
          </>
        )}
      </section>
      {openPlanModal && (
            <Modal
              isOpen={openPlanModalFn}
              onClose={closePlanModalFn}
              title={"Please select"}
              className={"plan-modal"}
            >
              <div className="Modal-custom">
                <TripPlanModal
                  id={selectedId}
                  closePlanModalFn={closePlanModalFn}
                  closeModal={() => setDisplayModal(false)}
                />
              </div>
            </Modal>
          )}   
    </div>
  ) : (
    <Spinner loading={isLoading} />
  );
};

import { axios } from "../../utils/axios";

export const registerOTP = (data) => {
  return axios.post(`/api-v1/account/register`, data);
};
export const verifyOTP = (data) => {
  return axios.post(`/api-v1/account/verify-otp`, data);
};
export const getCountries = (data) => {
  return axios.get(`/api-v1/account/get-countries`, data);
};
export const profileUpdate = (data) => {
  return axios.post(`/api-v1/account/update-profile`, data);
};

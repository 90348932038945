import { useState } from "react";
import { getTrips } from "../MyTrip/Api";
import Spinner from "../../components/Spinner";
import { addItinerary } from "./api";
import { useEffect } from "react";
import { API_URL } from "../../utils/config";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/Modal";
import { Locationpopup } from "../Tripplan/Locationpopup";
import { Country } from "../Tripplan/Country";

export const TripPlanModal = ({ id, closePlanModalFn, closeModal }) => {
  const [selectedBtn, setSelectedBtn] = useState("new-trip");
  const [selectedTrip, setSelectedTrip] = useState();
  const [listOfTrip, setListOfTrip] = useState();
  const [showListOfTrip, setShowListOfTrip] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [displayCountryModal, setDisplayCountryModal] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [depCountryId, setDepCounrtyId] = useState();

  useEffect(() => {
    getTrips().then((resp) => {
      setIsLoading(false);
      setListOfTrip(resp);
      setSelectedTrip(resp?.data[0]?.id);
    });
  }, []);
  const proceedToItinerary = () => {
    if (selectedBtn !== "new-trip") {
      setIsLoading(true);
      if (listOfTrip?.data?.length === 1) {
        let data = new FormData();
        data.append("location_id", id);

        data.append("trip_id", listOfTrip?.data[0]?.id);
        addItinerary(data)
          .then((resp) => {
            closePlanModalFn();
            closeModal();
            setIsLoading(false);
            navigate("/tripplan2");
          })
          .catch((err) => {
            setIsLoading(false);
          });
      } else if (listOfTrip?.data?.length !== 0) {
        console.log("ji");
        closeModal();

        setIsLoading(false);
        setShowListOfTrip(true);
      }
    } else {
      setDisplayModal(true);
    }
  };
  const handleAddToItineraryFn = (selectedId) => {
    setIsLoading(true);
    let data = new FormData();
    data.append("location_id", id);

    data.append("trip_id", selectedId);
    addItinerary(data)
      .then((resp) => {
        closePlanModalFn();
        closeModal();
        // navigate("/tripplan2");
        navigate("/tripplan2", {
          state: {
            // selectedCountry: countryId,
            trip_id: selectedId
          },
        });

        setIsLoading(false);
        toast.success(resp?.message);
      })
      .catch((err) => {
        closePlanModalFn();
        closeModal();
        setIsLoading(false);
      });
  };
  return !loading ? (
    <>
      <div className={loading ? "h-50" : "plan-modal"}>
        {showListOfTrip === false ? (
          <div className="plan-body">
            <div
              className={
                selectedBtn === "new-trip"
                  ? "active radio-wrap flex items-center gap-2"
                  : "radio-wrap flex items-center gap-2"
              }
            >
              <input
                id="radio-1"
                class="radio-custom"
                value="new-trip"
                name="radio-group"
                type="radio"
                defaultChecked
                onClick={(e) => {
                  setSelectedBtn(e.target.value);
                }}
              />
              <label
                for="radio-1"
                class="radio-custom-label flex items-center gap-2"
              >
                <div>
                  <p className="bold-text">Start a new trip</p>
                  <p className="light-text">
                    Build your itinerary from scratch
                  </p>
                </div>
              </label>
            </div>
            {listOfTrip?.data?.length === 0 ? (
              ""
            ) : (
              <div
                className={
                  selectedBtn === "current-trip"
                    ? "active radio-wrap flex items-center gap-2"
                    : "radio-wrap flex items-center gap-2"
                }
              >
                <input
                  id="radio-2"
                  class="radio-custom"
                  value="current-trip"
                  name="radio-group"
                  type="radio"
                  onClick={(e) => {
                    setSelectedBtn(e.target.value);
                  }}
                />
                <label
                  for="radio-2"
                  class="radio-custom-label flex items-center gap-2"
                >
                  <div>
                    <p className="bold-text">Add to existing trips</p>
                    <p className="light-text">
                      Add this event your existing trips
                    </p>
                  </div>
                </label>
              </div>
            )}
            <br />
            <button
              className="primary-button"
              onClick={() => {
                proceedToItinerary();
              }}
            >
              Confirm
            </button>
          </div>
        ) : (
          <>
            <div className={loading ? "plan-h" : "plan-body"}>
              {listOfTrip?.data?.map((ele, i) => {
                return (
                  <div
                    className={
                      parseInt(selectedTrip) === parseInt(ele?.id)
                        ? "active radio-wrap flex items-center gap-2"
                        : "radio-wrap flex items-center gap-2"
                    }
                    key={i}
                  >
                    <input
                      id={ele?.id}
                      class="radio-custom"
                      value={ele?.id}
                      name="radio-group"
                      type="radio"
                      defaultChecked={
                        parseInt(selectedTrip) === parseInt(ele?.id)
                      }
                      onClick={(e) => {
                        console.log(e.target.value, ele?.id, "elelelell");
                        setSelectedTrip(e.target.value);
                      }}
                    />
                    <label
                      for={ele?.id}
                      class="radio-custom-label flex items-center gap-2"
                    >
                      <div>
                        {/* <p className="bold-text">Start a new trip</p>
              <p className="light-text">Build your itinerary from scratch</p> */}
                        <div className="flex justify-between items-center gap-2">
                          <img src={API_URL + ele?.country?.flag} alt="place" />
                          <p className="bold-text">
                            Trip to {ele?.country?.name}
                          </p>
                        </div>
                      </div>
                    </label>
                  </div>
                );
              })}
            </div>
            <br />
            <button
              className="primary-button"
              onClick={() => {
                handleAddToItineraryFn(selectedTrip);
              }}
            >
              Confirm
            </button>
          </>
        )}
        <Modal
          isOpen={displayModal}
          onClose={() => {
            setDisplayModal(false);
            document.body.style.overflow = "unset";
          }}
          title="Your Location"
        >
          <Locationpopup
            onSubmit={() => setDisplayModal(false)}
            setDisplayModal={setDisplayModal}
            setDepCounrtyId={setDepCounrtyId}
            setDisplayCountryModal={setDisplayCountryModal}
          />
        </Modal>
        <Modal
          isOpen={displayCountryModal}
          onClose={() => {
            setDisplayCountryModal(false);
            document.body.style.overflow = "unset";
          }}
          title="Country"
        >
          <Country
            // onhandleSubmit={() => handleSubmitPlan()}
            depCountryId={depCountryId}
            setDisplayCountryModal={setDisplayCountryModal}
            // setDepartureCounrtyId={setDepartureCounrtyId}
          />
        </Modal>
      </div>
    </>
  ) : (
    <Spinner loading={loading} />
  );
};

import React from "react";
import logo from "../../assets/images/logo/footerlogo.svg";
export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer-main">
      <div className="footer-div">
        <p className="left-text">
          Copyright of Trundle Technology LLP, {currentYear}
        </p>
        <img className="right-text" src={logo} alt="trundle" />
      </div>
    </footer>
  );
}

import { useState } from "react";

export const Progress = ({done}) => {
    console.log(done, "done")
	const [style, setStyle] = useState({
        opacity: 1,
			width: `30%`
    });
	
	// setTimeout(() => {
		// const newStyle = {
		// 	opacity: 1,
		// 	width: `${done}%`
		// }
		
	
	// }, 200);
	
	return (
		<div className="progress">
			<div className="progress-done" style={style}>
				{/* {done}% */}
			</div>
		</div>
	)
}
import { MainLayout } from "../../components/MainLayout";
import tripActive from "../../assets/images/icon/trip-active.svg";
import cal from "../../assets/images/icon/free_cancellation.png";
import noTrip from "../../assets/images/background/noTrip.png";
import headerAction from "../../assets/images/icon/headerAction.png";

import { useNavigate } from "react-router-dom";
import { BottomTabs } from "../../components/BottomTabs";
import { useEffect, useState } from "react";
import { getTrips } from "./Api";
import { API_URL } from "../../utils/config";
import Modal from "../../components/Modal";
import { Country } from "../Tripplan/Country";
import { Locationpopup } from "../Tripplan/Locationpopup";
import { useAuth } from "../../components/authentication";

export const MyTrip = () => {
  const navigate = useNavigate();
  const {authUser} = useAuth();
  const [tripData, setTripData] = useState();
  const [countryId, setCounrtyId] = useState()
  const [displayModal, setDisplayModal] = useState(false);
  const [displayCountryModal, setDisplayCountryModal] = useState(false);
  useEffect(() => {
    if(authUser) {
    getTrips().then((resp) => {
      setTripData(resp);
    });
  }
  }, []);
  const [selectedMenu, setSelectedMenu] = useState(1);
  const onSelectMenu = (id) => {
    if (selectedMenu !== id) {
      setSelectedMenu(id);
    } else {
      setSelectedMenu(-1);
    }
  };
  useEffect(() => {
    if (!authUser) {
      navigate("/login");
    }
  }, [authUser]);
  return (
    <MainLayout>
      <div className="mob-blk">
        <div className="container">
          <div className="flex items-center mob-title-wrap explore-wrap">
            <button
              className="transparent-button"
              onClick={() => {
                navigate(-1);
              }}
            >
              <img src={headerAction} alt="header" />
            </button>
            <div className="explore">
              <p className="text-center">My Trip</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="my-trip-blk">
          <div className="heading flex items-center gap-2">
            <img src={tripActive} alt="trip" style={{ opacity: 0.6 }} />
            <p>My Trip</p>
          </div>
          {tripData?.data.length !== 0 ? (
            <div className="my-trip-wrap flex flex-col gap-2">
              {tripData?.data?.map((ele) => (
                <div className="flex justify-between items-center trip-wrap">
                  <div className="flex justify-between items-center gap-2">
                    <img src={API_URL + ele?.country?.flag} alt="place" />
                    <p className="trip-city">Trip to {ele?.country?.name}</p>
                  </div>
                  <div className="">
                    <button
                      className="trip-set-btn flex items-center gap-2"
                      onClick={() => {
                        navigate("/trip-details", {
                          state: {
                            data: ele?.id,
                          },
                        });
                      }}
                    >
                      <img src={cal} alt="cal" /> Set your trip date for better
                      assistance
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="my-trip-wrap flex justify-center items-center flex-col gap-2">
              <img src={noTrip} alt="notrip" />
              <p className="trip-city">No trip found.</p>
              <p className="grey-text">Plan your first trip today.</p>
              <div className="plan-trip">
                <button className="primary-button" onClick={() => {
                  setDisplayModal(true)
                }}>
                  &nbsp;&nbsp; + Plan A Trip &nbsp;&nbsp;
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
            isOpen={displayModal}
            onClose={() => setDisplayModal(false)}
            title="Your Location"
          >
            <Locationpopup
              onSubmit={() => setDisplayModal(false)}
              setDisplayModal={setDisplayModal}
              setCounrtyId={setCounrtyId}
              setDisplayCountryModal={setDisplayCountryModal}
            />
          </Modal>
          <Modal
            isOpen={displayCountryModal}
            onClose={() => setDisplayCountryModal(false)}
            title="Country"
          >
            <Country
              // onhandleSubmit={() => handleSubmitPlan()}
              countryId={countryId}
              setDisplayCountryModal={setDisplayCountryModal}
              // setDepartureCounrtyId={setDepartureCounrtyId}
            />
          </Modal>
      <div className="bottom-menu">
        <BottomTabs onSelectMenu={onSelectMenu} selectedMenu={selectedMenu} />
      </div>
    </MainLayout>
  );
};

import { Route, Routes } from "react-router-dom";
import "./assets/styles/styles.css";

import { SignUp } from "./pages/SignUp";
import { OTPScreen } from "./pages/SignUp/OTPScreen";
import { Login } from "./pages/Login";
import { Home } from "./pages/Home";
import { Activities } from "./pages/Activities";
import { Toaster } from "react-hot-toast";
import { AuthProvider } from "./components/authentication/index";
import { Tripplan } from "./pages/Tripplan";
import { Createprofile } from "./pages/SignUp/Createprofile";
import { Success } from "./pages/Success";
import { Profile } from "./pages/BottomPages/Profile";
import { Help } from "./pages/BottomPages/Help";
import { EditProfile } from "./components/Header/EditProfile";
import { MyTrip } from "./pages/MyTrip";
import { TripDetails } from "./pages/MyTrip/TripDetailsPage";
import { Tripplan2 } from "./pages/Tripplan/tripplan2";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/otp" element={<OTPScreen />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/activities/*" element={<Activities />} />
        <Route exact path="/createprofile" element={<Createprofile />} />
        <Route exact path="/success" element={<Success />} />
        <Route exact path="/planatrip" element={<Tripplan />} />
        <Route exact path="/tripplan2" element={<Tripplan2 />} />

        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/help" element={<Help />} />
        <Route exact path="/edit" element={<EditProfile />} />
        <Route exact path="/my-trip" element={<MyTrip />} />
        <Route exact path="/trip-details" element={<TripDetails />} />



      </Routes>
      <Toaster />
    </AuthProvider>
  );
}

export default App;

/* eslint-disable react-hooks/exhaustive-deps */
import pass from "../../assets/images/icon/pass.png";
import locationOn from "../../assets/images/icon/location_on.png";
import time from "../../assets/images/icon/time.png";
// import {  useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getPlacesDetails } from "../Activities/api";
import map from "../../assets/images/icon/map.png";

import { useState } from "react";
import noImage from "../../assets/images/icon/no-image.png";
import Spinner from "../../components/Spinner";

export const DetailsLocationPopup = (props) => {
    // const navigate = useNavigate();
  //   const location = useLocation();
  const [getDetails, setDetails] = useState();
  const [loading, setLoading] = useState(false);
  // const divStyle = {
  //   display: props.displayModal ? "flex" : "none",
  // };
  // function closeModal(e) {
  //   e.stopPropagation();
  //   props.closeModal(false);
  //   document.body.style.overflow = 'unset';
  // }

  useEffect(() => {
    setLoading(true);
    let data = new FormData();
    data.append("id", props?.selectedId);
    getPlacesDetails(data).then((res) => {
      setLoading(false);
      setDetails(res?.data);
      props?.setDetailsOfLocation(res?.data)
    });
  }, [props?.selectedId]);
  console.log(props)
  return (

    !loading ? (
      <div className="main-content details-popup">
        {getDetails?.locationimage_set.length === 0 ? (
          <div className="img-blk">
            <img src={noImage} alt="places" />
          </div>
        ) : (
          <div className="img-blk">
            <img src={getDetails?.locationimage_set[0]} alt="places" />
          </div>
        )}
        <div className="content-blk">
          <p className="property">{getDetails?.type_of_event}</p>
          <p className="name">{getDetails?.name_of_location}</p>
          <div className="wrap">
            <p className="description">{getDetails?.description}</p>
          </div>
          <div className="wrap flex items-center justify-between entry-wrap">
            <div className="flex gap-2 items-center ">
              <img src={pass} alt="pass" />
              <p className="entry">Entry</p>
            </div>
            <p className="price">{getDetails?.cost}</p>
          </div>

          <div className="wrap flex flex-col items-start justify-between">
            <div className="flex gap-2 items-start ">
              <img src={locationOn} alt="pass" />
              <div className="flex flex-col gap-2">
                <p className="title-address">Address</p>
                <p className="description">{getDetails?.address}</p>
              </div>
            </div>
            <div className="get-direction">
              <button className="get-direction-btn" onClick={() => {
                // navigate(`${getDetails?.google_map}`)
                // window.location.href = getDetails?.google_map;
                window.open(getDetails?.google_map, '_blank');
              }}>
                <img src={map} alt="location" /> Get Direction
              </button>
            </div>
            <div className="wrap direction-wrap">
              <p>{getDetails?.access}</p>
            </div>
          </div>
          <div className="wrap flex items-start justify-between">
            <div className="wrap flex flex-col items-start justify-between">
              <div className="flex gap-2 items-start ">
                <img style={{ opacity: 0.6 }} src={time} alt="pass" />
                <div className="flex flex-col gap-2">
                  <p className="title-address">Timing</p>
                  <p className="description">{getDetails?.open_timings}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <Spinner loading={loading} />
    )
    //   </div>
    //   </div>
  );
};

import React from "react";

export const InputField = React.forwardRef(
  ({
    placeholder,
    type,
    name,
    register,
    label,
    value,
    defaultValue,
    disabled,
  }) => {
    return (
      <>
        <div className="input-wrap">
          <label className="label">{label}*</label>
          <br />

          <input
            placeholder={placeholder}
            type={type}
            name={name}
            value={value}
            disabled={disabled}
            defaultValue={defaultValue}
            /* register your input into the hook by invoking the "register" function */
            {...register(name)}
          />
        </div>
      </>
    );
  }
);

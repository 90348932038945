// import { useNavigate } from "react-router-dom";
import { Header } from "../../components/Header";
import plantrip from "../../assets/images/icon/plan-trip.svg";
import plus from "../../assets/images/icon/plus.png";
import dots from "../../assets/images/icon/dots.png";
import noImage from "../../assets/images/icon/no-image.png";
import locationIcon from "../../assets/images/icon/location.png";

import threedot from "../../assets/images/icon/plantrip/3dot-button.svg";
import { MainLayout } from "../../components/MainLayout";
import { API_URL } from "../../utils/config";
import { useState } from "react";
import { useEffect } from "react";
import { getTripItinerary } from "../MyTrip/Api";
import ScrollableModal from "../../components/CustomModal/ScrollableModal";
import { useAuth } from "../../components/authentication";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "../../components/Modal";
import { NotSignedUpModal } from "./NotSignedUpMpdal";
import { DetailsLocationPopup } from "../MyTrip/DetailsLocationPopup";
import { Viewmodal } from "../Activities/Viewmodal";

export const Tripplan2 = () => {
  const [itineraryData, setItineraryData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);

  const location = useLocation()
  console.log(location , "locar")
  const [selectedId, setSelectedId] = useState();
  const [signupModal, setSignUpModal] = useState(false);
  const navigate = useNavigate();
  const openModal = () => {
    setIsModalOpen(true);
  };
  const openEventModal = () => {
    setIsEventModalOpen(true);
  };
  const [getDetailsOfLocation, setDetailsOfLocation] = useState();

  const { authUser } = useAuth();
  // Retrieve the array from local storage
  let storedArray = localStorage.getItem("cart");
  let countryInfo = JSON.parse(localStorage.getItem("planTrip")) || {};

  // Convert the retrieved string back to an array
  let itineraryArray = JSON.parse(storedArray);
  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "unset";
  };
  const closeEventModal = () => {
    setIsEventModalOpen(false);
    document.body.style.overflow = "unset";
  };
  useEffect(() => {
    // let data = new FormData();
    if (authUser) {
      let tripdata = new FormData();

      // data.append("id", location?.state?.data);
      tripdata.append("trip_id", location?.state?.trip_id);

      // getTripDetails(data).then((resp) => {
      //   setDataOfTrip(resp);
      // });
      getTripItinerary(tripdata).then((resp) => {
        setItineraryData(resp?.data);
      });
    } else {
      setItineraryData(itineraryArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSaveItinerary = () => {
    // let products = JSON.parse(localStorage.getItem("cart")) || [];
    // localStorage.setItem("itinerary", JSON.stringify(products));
    openSignupModalFn();
  };
  console.log(itineraryArray, "iti");
  const openSignupModalFn = () => {
    setSignUpModal(true);
    document.body.style.overflow = "hidden";
  };
  const closeSignupModalFn = () => {
    setSignUpModal(false);
    document.body.style.overflow = "unset";
  };
  console.log(itineraryData, itineraryData)
  // const navigate = useNavigate();
  return (
    <MainLayout>
      <div className="plan-a-trip">
        <div
          className="container plantrip trip-details"
          style={
            !itineraryData?.length
              ? { height: "60vh" }
              : { height: "auto" }
          }
        >
          <div className="r-plantrip-section">
            <div className="flex r-plantrip">
              <img src={plantrip} alt="plantrip" />
              <p className="r-plantrip-text">Plan a trip</p>
            </div>
            <div className="content-area w-100">
              <div className="sub-content1">
                {itineraryData &&
                  itineraryData?.map((ele) => (
                    <div
                      className="flex  items-center"
                      style={{ gap: 40, marginBottom: 40 }}
                    >
                      <div className="status flex flex-col items-center gap-2">
                        <div className="dots"></div>
                        <div className="line"></div>
                      </div>

                      <div className="card">
                        {/* <div className="img-wrap">
                        <img src={ele?.location?.locationimage_set[0].img} alt="img" />
                      </div> */}
                        {ele?.location?.locationimage_set?.length === 0 ? (
                          <div className="img-wrap">
                            <img src={noImage} alt="places" />
                          </div>
                        ) : (
                          <div className="img-wrap">
                            <img
                              src={
                                !itineraryArray
                                  ? API_URL +
                                    ele?.location?.locationimage_set[0]
                                  : ele?.location?.locationimage_set[0]
                              }
                              alt="places"
                            />
                          </div>
                        )}
                        <div className="details">
                          <p className="property-type">
                            {ele?.location?.type?.name}
                          </p>
                          <p className="name">
                            {ele?.location?.name_of_location}
                          </p>
                          <div className="address-wrap flex justify-between items-start">
                            <p className="address">{ele?.location?.address}</p>
                            <div className="icons flex items-center gap-2">
                              <div className="">
                                <button
                                  className="secondary-button view-details-btn w-100"
                                  onClick={() => {
                                    // setOpenDetails(true);
                                    if(ele?.location?.category == 3) {
                                      openEventModal()
                                    }else{
                                    openModal();
                                    }
                                    setSelectedId(ele?.location?.id);
                                    document.body.style.overflow = "hidden";
                                  }}
                                >
                                  View Details
                                </button>
                              </div>
                              <div className="">
                                <button
                                  className="transparent-button"
                                  onClick={() => {
                                    window.open(
                                      ele?.location?.google_map,
                                      "_blank"
                                    );
                                  }}
                                >
                                  <img src={locationIcon} alt="location" />
                                </button>
                              </div>
                              <div className="">
                                <button className="transparent-button">
                                  <img src={dots} alt="dots" />
                                </button>
                              </div>
                            </div>
                          </div>

                          <p className="entry">
                            {ele?.location?.cost_description}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="floating-icon">
            <button
              className="transparent-button"
              onClick={() => {
                navigate("/activities/places", {
                  state: {
                    tab: "places",
                    selectedCountry: !authUser ? countryInfo?.country_id : location?.state?.selectedCountry,
                    trip_id: location?.state?.trip_id
                  },
                });
              }}
            >
              <img src={plus} alt="plus" />
            </button>
          </div>
        </div>
        <div className="footer">
          <div className="flex justify-center items-center">
            <button
              className="secondary-button"
              onClick={() => {
                handleSaveItinerary();
              }}
            >
              Save itinerary
            </button>
          </div>
        </div>
      </div>
      {signupModal && (
        <Modal
          isOpen={openSignupModalFn}
          onClose={closeSignupModalFn}
          title={""}
          className={"plan-modal"}
        >
          <div className="Modal-custom">
            <NotSignedUpModal
              // id={selectedId}
              closeSignupModalFn={closeSignupModalFn}
              // closeModal={closeModal}
            />
          </div>
        </Modal>
      )}
      {
        openEventModal && (
          <Modal isOpen={isEventModalOpen} onClose={() => setIsEventModalOpen(false)}>
          <Viewmodal
            hideProceed={true}
            eventId={selectedId}
            countryId={countryInfo?.country_id}
            closeModal={() => setIsEventModalOpen(false)}
            openPlanModalFn={() => {
              console.log("hello")
            }}
            closePlanModalFn={() => {
              console.log("hello")
            }}
          />
        </Modal>
        )
      }
        <ScrollableModal isOpen={isModalOpen} onClose={closeModal}>
            <div className="scrollable-content">
              <DetailsLocationPopup
                selectedId={selectedId}
                setDetailsOfLocation={setDetailsOfLocation}
              />

              {/* Content that exceeds the height of the modal */}
            </div>
          </ScrollableModal>
    </MainLayout>
  );
};

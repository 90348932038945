import avatar1 from "../../assets/images/icon/Avatar/avatar1.png";
import avatar2 from "../../assets/images/icon/Avatar/avatar2.png";
import avatar3 from "../../assets/images/icon/Avatar/avatar3.png";
import avatar4 from "../../assets/images/icon/Avatar/avatar4.png";
import avatar5 from "../../assets/images/icon/Avatar/avatar5.png";
import avatar6 from "../../assets/images/icon/Avatar/avatar6.png";
import avatar7 from "../../assets/images/icon/Avatar/avatar8.png";
import avatar8 from "../../assets/images/icon/Avatar/avatar9.png";
import avatar9 from "../../assets/images/icon/Avatar/avatar10.png";
import avatar10 from "../../assets/images/icon/Avatar/avatar11.png";
import avatar11 from "../../assets/images/icon/Avatar/avatar12.png";
import avatar12 from "../../assets/images/icon/Avatar/avatar13.png";
// import { useState } from "react";

export const AvatarModal = ({ files, setSelectedImg, selectedImg, setFile, closeAvatarModal, closeProfileModal, setIsAvatarEdit }) => {

  return (
    <>
      <div className="avatar-wrap">
        <img
          src={selectedImg}
          alt="avatar"
          className="flex m-auto selected-avatar"
        />
        <p className="choose-avatar">Choose Avatar</p>
        <div className="select-avatar">
          <button
            className={
              selectedImg === avatar2
                ? "active transparent-button m-0 p-0"
                : " transparent-button m-0 p-0"
            }
            onClick={() => {
              setSelectedImg(avatar2);
            }}
          >
            <img src={avatar2} alt="avatar" />
          </button>
          <button
            className={
              selectedImg === avatar3
                ? "active transparent-button m-0 p-0"
                : " transparent-button m-0 p-0"
            }
            onClick={() => {
              setSelectedImg(avatar3);
            }}
          >
            <img src={avatar3} alt="avatar" />
          </button>
          <button
            className={
              selectedImg === avatar4
                ? "active transparent-button m-0 p-0"
                : " transparent-button m-0 p-0"
            }
            onClick={() => {
              setSelectedImg(avatar4);
            }}
          >
            <img src={avatar4} alt="avatar" />
          </button>
          <button
            className={
              selectedImg === avatar5
                ? "active transparent-button m-0 p-0"
                : " transparent-button m-0 p-0"
            }
            onClick={() => {
              setSelectedImg(avatar5);
            }}
          >
            <img src={avatar5} alt="avatar" />
          </button>
          <button
            className={
              selectedImg === avatar1
                ? "active transparent-button m-0 p-0"
                : " transparent-button m-0 p-0"
            }
            onClick={() => {
              setSelectedImg(avatar1);
            }}
          >
            <img src={avatar1} alt="avatar" />
          </button>
          <button
            className={
              selectedImg === avatar6
                ? "active transparent-button m-0 p-0"
                : " transparent-button m-0 p-0"
            }
            onClick={() => {
              setSelectedImg(avatar6);
            }}
          >
            <img src={avatar6} alt="avatar" />
          </button>
          <button
            className={
              selectedImg === avatar7
                ? "active transparent-button m-0 p-0"
                : " transparent-button m-0 p-0"
            }
            onClick={() => {
              setSelectedImg(avatar7);
            }}
          >
            <img src={avatar7} alt="avatar" />
          </button>
          <button
            className={
              selectedImg === avatar8
                ? "active transparent-button m-0 p-0"
                : " transparent-button m-0 p-0"
            }
            onClick={() => {
              setSelectedImg(avatar8);
            }}
          >
            <img src={avatar8} alt="avatar" />
          </button>
          <button
            className={
              selectedImg === avatar9
                ? "active transparent-button m-0 p-0"
                : " transparent-button m-0 p-0"
            }
            onClick={() => {
              setSelectedImg(avatar9);
            }}
          >
            <img src={avatar9} alt="avatar" />
          </button>
          <button
            className={
              selectedImg === avatar10
                ? "active transparent-button m-0 p-0"
                : " transparent-button m-0 p-0"
            }
            onClick={() => {
              setSelectedImg(avatar10);
            }}
          >
            <img src={avatar10} alt="avatar" />
          </button>
          <button
            className={
              selectedImg === avatar11
                ? "active transparent-button m-0 p-0"
                : " transparent-button m-0 p-0"
            }
            onClick={() => {
              setSelectedImg(avatar11);
            }}
          >
            <img src={avatar11} alt="avatar" />
          </button>

          <button
            className={
              selectedImg === avatar12
                ? "active transparent-button m-0 p-0"
                : " transparent-button m-0 p-0"
            }
            onClick={() => {
              setSelectedImg(avatar12);
            }}
          >
            <img src={avatar12} alt="avatar" />
          </button>
        </div>
      </div>
      <div className="footer-details">
        <button
          className="primary-button"
          onClick={() => {
            function DataURIToBlob(dataURI) {
              const splitDataURI = dataURI.split(",");
              const byteString =
                splitDataURI[0].indexOf("base64") >= 0
                  ? atob(splitDataURI[1])
                  : decodeURI(splitDataURI[1]);
              const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

              const ia = new Uint8Array(byteString.length);
              for (let i = 0; i < byteString.length; i++)
                ia[i] = byteString.charCodeAt(i);

              return new Blob([ia], { type: mimeString });
            }
            const file = DataURIToBlob(selectedImg);
            setFile(file);
            closeAvatarModal();
            setIsAvatarEdit(true)
            closeProfileModal()
          }}
        >
          Save Changes
        </button>
      </div>
    </>
  );
};

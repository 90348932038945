
import plantrip from "../../assets/images/icon/plan-trip.svg";
import plantripalert from "../../assets/images/background/alert-plantrip.svg";
import plantripalertmob from "../../assets/images/background/alert-plantrip-mob.svg";

import addicon from "../../assets/images/background/addicon.svg";

import { MainLayout } from "../../components/MainLayout";
import { useLocation, useNavigate } from "react-router-dom";

export const Tripplan = () => {
const location = useLocation();
const navigate = useNavigate()
const handleShowMore = () => {
  navigate("/activities/places", {
    state: {
      tab: "places",
      selectedCountry: location?.state?.selectedCountry,
      trip_id: location?.state?.trip_id
    },
  });
};

  // const navigate = useNavigate();
  return (
    <MainLayout>
      <div className="r-plan-a-trip">

        <div className="r-plantrip-section">
          <div className="flex r-plantrip">
            <img src={plantrip} alt="plantrip" />
            <p className="r-plantrip-text">Plan a trip</p>
          </div>
          <div className="alert-div">
            <img src={plantripalert} alt="Add places to your itinerary" />
          </div>
          <div className="alert-div-mob">
            <img src={plantripalertmob} alt="Add places to your itinerary" />
          </div>
          <div className="addicon">
            <button onClick={() => handleShowMore()}>
              <img src={addicon} alt="Add" />
            </button>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
